import React, { useState, useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { HashLink } from 'react-router-hash-link';
import { Link, useParams } from "react-router-dom";
import './loaderstyles.css';

const EditorsPick = () => {
    const [token, setToken] = useState()
    const [countOne, setCountOne] = useState(0)
    const [countTwo, setCountTwo] = useState(0)
    const [editorsPick, setEditorsPick] = useState([])
    const [isLoading, setLoading] = useState(false)

    let { id } = useParams();

    const apiUrl = `/auth/local`;
    const callEditorsPick = `${process.env.REACT_APP_BLOGS_API}/blogs?publish_status=Publish&editors_pick=true&_sort=date_time_publish:DESC&_start=${countOne}&_limit=${countTwo}`
    // const callEditorsPick = `/blogs?publish_status=Publish&editors_pick=true&_start=${countOne}&_limit=${countTwo}`
    const loadMorebutton= async () =>{
        getEditorsPickData();
    }
   

    const getEditorsPickData = async (e) => {
        
        setLoading(true)
        window.scrollBy(0, 0);
        setEditorsPick([])
        var dataCoutone=countTwo+3;
        setCountTwo(dataCoutone)
          const dataParams = {
            token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
            id: id,
            countone:0,
            countTwo:dataCoutone,
          }
          await axios.post(`${process.env.REACT_APP_DOMAIN}/call-editors-pick-blogs`, dataParams).then((res) => { setEditorsPick(res.data) })
          setLoading(false)
        
      }

    // console.log(editorsPick)

    useEffect(() => {
        getEditorsPickData();
    }, [])

    const renderEditosPick = (
        <div className='row bgs-posts'>
            {editorsPick.length>0?editorsPick.map((items, index) => {
                let rawdate = new Date(items?.created_at)   
                
                let date = rawdate?.getDate()
                let month = rawdate?.toLocaleString('default', { month: 'long' })
                let year = rawdate?.getFullYear()
                return (
                    <div className="col-lg-4 col-md-6 col-sm-12 layout-simple">
                        <article id={items?.id} width="400px">
                            <HashLink elementId='root' className="bnt-featured-image-url" to={`/blogs/${items.url_slug}`}>
                                <img src={`${process.env.REACT_APP_BLOGS_API}/${items?.featured_image?.image_file?.url}`} alt={items?.featured_image?.alt_text? items?.featured_image?.alt_text:"Blog Images"} />
                            </HashLink>
                            <div className="desc_content">
                                <h2 id='card-title' className="bnt-title h3"><Link to={`/blogs/${items?.url_slug}`} state={{ from: `${items?.url_slug}` }}>{items?.title}</Link></h2>

                                <p className="post-meta">  
                                    <span className="published">{`${month} ${date}, ${year}`}</span> 
                                    {/* <span className="meta-item meta-views">{items.views === null ? 0 : items.views} Views</span>
                                    | */}
                                    <div className="bnt-category" style={{marginTop:'7px'}}>
                                        {items.categories.map((subItem, subIndex) => { return <span><Link to={`/blogs/topics/${subItem?.url_slug}`} state={{ from: `${subItem?.url_slug}` }} style={{ border: 'none' }} className="blog_categ" rel="category tag" >{subItem?.name}</Link>&nbsp;&nbsp;</span> })}
                                    </div>
                                </p>
                                <div className="bnt-content-cus">							
                                <p className='short-description' style={{ fontSize: '14px' }}>{parse(items?.page_content)}</p>
                                </div>
                            </div>
                        </article>
                    </div>
                )
            })
            :<i className="fa fa-spinner fa-spin" />}
              {editorsPick.length>0? <nav class="bgs-pagination bgs-load-more col-12 common_center top_padding_30 bottom_padding_30"><a d="getblogpagnination" class="loadmore-btn" onClick={loadMorebutton}><span>Load More
                            </span></a></nav> :""}
        </div>
    )

    return (
        <div>
            {isLoading ?  <i className="fa fa-spinner fa-spin" /> : renderEditosPick}
        </div>
    )
}

export default EditorsPick;