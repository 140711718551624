import React from 'react'
import { useEffect, useState } from 'react'
import axios from 'axios'
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const SearchKb = () => {

    const searchValue = localStorage.getItem('sValue')
    console.log(localStorage.getItem('sValue'))
  
    const [pageData, setPageData] = useState([])
    const [sValue, setSValue] = useState()

    const searchApi = `${process.env.REACT_APP_BLOGS_API}/knowledge-base-cms?title_contains=${searchValue}`

    // const callSearchApi = () => {
    //     axios.get(searchApi, {
    //         headers: {
    //             Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAsImlhdCI6MTY3NTE0ODQ5NiwiZXhwIjoxNjc3NzQwNDk2fQ.NW2cdbJDXHoo3FbqlOQTvyRtnZf0gBePqHv9bMo06zw`
    //         }
    //     }).then((res) => { setPageData(res.data) })
    // }

    const callSearchApi = async () => {
        
          const dataParams = {
            token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
            searchValue: searchValue
          }
          await axios.post(`${process.env.REACT_APP_DOMAIN}/call-search-kb`, dataParams).then((res) => { setPageData(res.data) }).catch((error) => { console.log(error) })
      
      }

    const navigate = useNavigate();
    const handleChange = (e) => {
        setSValue(e.target.value);
        localStorage.setItem('sValue', sValue)
    }
    const submit = () =>{
        navigate('/kb/search')
    }

    console.log(pageData)

    useEffect(() => {
        window.scroll(0, 0)
        callSearchApi()
    })

    return (
        <div>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link href="https://www.elitesiteoptimizer.com/public/assets/eso/images/eso-fav_icon.png" rel="icon" type="image/png" sizes="32x32" />
            <title>Link Analysis | KnowledgeBase | Elite Site Optimizer</title>
            <link rel="canonical" href="https://www.elitesiteoptimizer.com/kb/link-analysis/" />
            <meta name="description" content="Link checker sections help the user in analyzing all the links on their website and help in enhancing the website's link building activities." />
            <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            {/* facebook */}
            {/* twitter */}
            {/* linkedin */}
            {/* PAGE custom css   */}
            {/* PAGE custom jS   */}
            {/* PAGE Tracking code   */}
            {/*Google Tag Manager */}
            {/* Google Tag Manager */}
            {/* End Google Tag Manager */}
            {/*Google Analytics Code */}
            {/* Global site tag (gtag.js) - Google Analytics */}
            {/*Facebook Pixel Code */}
            {/* Facebook Pixel Code */}
            <noscript>
                &lt;img height="1" width="1"
                src="https://www.facebook.com/tr?id=553406781904038&amp;ev=PageView
                &amp;noscript=1" alt="Facebook"/&gt;
            </noscript>
            {/* End Facebook Pixel Code */}
            {/* custom_css and Js  */}
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/bootstrap.min.css" />
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/font-awesome.min.css" />
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/hfstyle.css" />
            <div id="et-main-area">
                
                <div id="main-content" className="kb_listing kb_knowledge_page kb_page">
                    <div className="container">
                        <h1>{searchValue}</h1>
                        <div className="pakb-header top_margin_50 col-md-7 left_padding_0">
                            
                        </div>
                        <div className="row">
                            {pageData.map((items, index) => {
                               
                                
                                return (
                                    <div>
                                        <ul className="pakb-list left_padding_15">
                                          
                                            <li><i className="si-file-text2" /> <Link to={`/knowledge-based/${items.url_slug}`} state={{ from: `${items.url_slug}` }} >
                                            {
                                            (items.title!==null && items.title!=='') ? items.title : "No Data Found" }
                                            </Link></li>
                                                                
                                        </ul>  
                                    </div>                  
                                )        
                            })}
                            <div id="sidebar" className="col-md-4 col-12 bottom_padding_30">
                                <div id="search-3" className="kb_widget kb_search">
                                    <form role="search" method="post" id="searchform" className="searchform" action="https://www.elitesiteoptimizer.com/kb/search/">
                                        <div>
                                            <input type="hidden" name="_token" defaultValue="NY9GcHC4zPLnv4nn4CgCvi1qVxrVRjdtLXPZYb4c" />										<label className="screen-reader-text" htmlFor="s">Search for:</label>
                                            <input type="text" defaultValue name="s" id="tags" />
                                            <input type="submit" id="searchsubmit" defaultValue="Search" />
                                        </div>
                                    </form>
                                </div>
                                
                                {/* end .kb_widget */}
                                
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default SearchKb;