import React , { useState, useEffect } from "react";
import axios from 'axios';
import {Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Sidebar from "./Sidebared.js";
import './Dashboard.css' 
import { MDBDataTable } from 'mdbreact';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import { useNavigate } from 'react-router-dom';
import { Link, useParams } from "react-router-dom";


const Whitelabelview = () => {

  const [posts, setPosts] = useState([]);
  const [usersForRender, setUsersForRender] = useState([]);
    const [cardData, setCardData] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    let querystring = useParams();
    let contactid= querystring.id
    useEffect(() => {   
        callUserdataApi()    
    }, [])
    
    const callUserdataApi = async () => {
      setLoading(true)
      // const datavalue= await axios.post(`https://staging.elitesiteoptimizer.com/api/whitelabeldetails/${contactid}`)
      const datavalue= await axios.post(`${process.env.REACT_APP_DOMAIN}/call-view-response-whitelabel`, {
        id: contactid})
      const dataval=datavalue.data.data[0];
      console.log(dataval);
      setCardData(dataval);
      setLoading(false)
  }
  return (

    <section className="menu-table tog_menu_table_exit">
      <div className="user">
        <div className="user5">
        <h3>White Label Form list</h3>
        <div className="link-set">
          <div className="link">
            <a href="">
              Home <span> &gt;</span>{" "}
            </a>
            <Link to='/white-label' className="active">
              {" "}
              White Label Form list
            </Link>
          </div>
        
        </div>

        </div>
       
        <div className="tset">
       
<div class="box-col-12 ">
   <div class="card">
      <div class="card-body">

  <b>White Label Form list:</b>

  <table border="none" width="100%">
    <tbody>
     
      <tr>
        <td>Name</td>
        <td>{cardData.yourName}</td>
      </tr>
    
      <tr>
        <td>Phone</td>
        <td>{cardData.phone}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>{cardData.email}</td>
      </tr>
      <tr>
        <td>Company Name</td>
        <td>{cardData.Company_Name}</td>
      </tr>
      <tr>
        <td>Company Website</td>
        <td>{cardData.Company_Website}</td>
      </tr>
      <tr>
        <td>Last Year Turnover</td>
        <td>{cardData.Last_year_turnover}</td>
      </tr>
      <tr>
        <td>Role</td>
        <td>{cardData.Your_Role_Company}</td>
      </tr>
      <tr>
        <td>Seo Services</td>
        <td>{cardData.seo_service}</td>
      </tr>
      <tr>
        <td>Country</td>
        <td>
        {cardData.country}
        </td>
      </tr>
      <tr>
        <td>Created Date</td>
        <td>{cardData.created_date}</td>
      </tr>
    
    </tbody>
  </table>


      
      </div>
   </div>
</div>
         

        </div>
       
      </div>
    </section>
   

   
  );
}


  export default  Whitelabelview ;
