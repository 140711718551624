import React, { useEffect } from 'react';
import EmailValidatorForm from '../EmailValidatorForm/EmailValidatorForm';
import InstantAuditTheme from './../../../images/astro/instant-audit-theme.png';
import RocketLeft from './../../../images/astro/rocket-left.png';
import './instant-audit-features.css';

const InstantAuditFeatures = () => {
    useEffect(() => {
        document.title = 'Instant Audit Features | SEO Automation Tool';
    }, []);

    // const [message, setMessage] = useState('');

    // const handleChange = (event) => {
    //     setMessage(event.target.value);
    //     // var userDomain = value;
    //     if (event.target.value === 'localhost') {
    //         console.log(true);
    //     } else {
    //         console.log(false);
    //     }
    // };

    // let localDomain = document.domain;

    // let localDomain = document.domain;
    // let userDomain = 'localhost';
    // let result = localDomain.localeCompare(userDomain);
    // console.log(result);


    return (
        <div className="et-main-area"> 
            <div className="cta_bg_wrap_ins">
                <div className="instant_container">
                    <div className='instant-audit-container_one instant-audit-container_one_of_one'>
                        <img src={InstantAuditTheme} alt='instant audit theme'/>
                        <img className='rocket-left-1' src={RocketLeft} alt='instant audit theme two'/>
                        <div className='email-validation-form'>
                            <EmailValidatorForm />
                        </div>
                        
                    </div>
                </div>

            </div>
        </div>
    )
}

export default InstantAuditFeatures;