import React , { useState, useEffect } from "react";
import axios from 'axios';
import {Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Sidebar from "./Sidebared.js";
import './Dashboard.css' 
import { MDBDataTable } from 'mdbreact';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
const ErrorDb = () => {
    const [cardData, setCardData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {   
        callUserdataApi()    
    }, [])
    
    const callUserdataApi = async () => {
           
        setLoading(true)
        const datavalue= await axios.post(`${process.env.REACT_APP_DOMAIN}/call-errorDb-api`)
        setCardData(datavalue.data);
        setLoading(false)
    }

  const data = {
    columns: [
     
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Site Url',
        field: 'urls',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Date',
        field: 'created_at',
        sort: 'asc',
        width: 150
      }
    ],
    rows:cardData
  };

  return (

    <section className="menu-table tog_menu_table_exit">
      <div className="user">
        <div className="user5">
        <h3>User Data list</h3>
        <div className="link-set">
          <div className="link">
            <a href="">
              Home <span> &gt;</span>{" "}
            </a>
            <a href="" className="active">
              {" "}
              User list
            </a>
          </div>
        
        </div>

        </div>
       
        <div className="tset">
          {/* <table>
            <tbody>
              <tr>
                <th>S.No</th>
                <th>Email</th>
                <th>Site Ul</th>
                <th>Date</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Contact@egrovesys.com</td>
                <td>https://www.egrovesys.com/</td>
                <td>30/05/2023</td>
              </tr>
            </tbody>
          </table> */}
              <MDBDataTable 
    theadColor='#454f71'
    small
    responsive 
    bordered
    hover
    noBottomColumns
    exportToCSV 
    data={data}
    />
         

        </div>
       
      </div>
    </section>
   

   
  );
}


  export default ErrorDb