import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { HashLink } from 'react-router-hash-link';
import contact_us_bg from './../../images/eso/home_image/contact_banner_two.png'
// import bg_3 from '../../images/eso/home_image/bg_3.png';
// import bg_4 from '../../images/eso/home_image/bg_4.png';
import errorimg from '../../images/PNG/countryerror.png';

import $ from 'jquery';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Countryerrorpage = () => {

  const [loading, setLoading] = useState(false);
    return (
      <>
      <div id="et-main-area">
  <div className="container page_error top_padding_50 bottom_padding_50">
    <div className="row">
      <div className="col-12">
        <div className="common_text_inner common_center">
          <img
            src={errorimg}
            alt={404}
            style={{ maxWidth: 400, margin:'0 auto' }}
          />
          <h4 className="top_margin_50" style={{ textAlign: "justify" }}>
          It seems like you're trying to access Elite Site Optimizer from a location outside of our supported regions. We apologize for any inconvenience this may cause. If you have any questions or need further assistance,please don't hesitate to reach out to us at <strong>
              <a
                href="mailto:support@elitesiteoptimizer.com"
                style={{ color: "#05173e", textDecoration: "none" }}
              >
                support@elitesiteoptimizer.com
              </a>
            </strong>
          </h4>
          
        </div>
      </div>
    </div>
  </div>
</div>

      </>
    );
  }

export default Countryerrorpage;