import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Categories from './Categories';
import RecentPosts from './RecentPosts';
import './kb.css';

const DynamicKbTwo = () => {

    let { idtwo } = useParams()

    // const showMoreApi = `${process.env.REACT_APP_BLOGS_API}/knowledge-base-cms?url_slug=${idtwo}`
    const [pageData, setPageData] = useState([])
    const [title, setTitle] = useState()
    const [pageContent, setPageContent] = useState()
    const [sValue, setSValue] = useState()


    const navigate = useNavigate();
    const handleChange = (e) => {
        setSValue(e.target.value);
        localStorage.setItem('sValue', sValue)
    }
    const onFormsubmit = e =>{
        if (e.keyCode === 13 || e.key === 'Enter' ) {
        navigate('/kb/search')
        }
    }
    const submit = () => {
        navigate('/kb/search')
    }

    // const callSlugApi = async () => {
    //     await axios.get(showMoreApi, {
    //         headers: {
    //             Authorization: `Bearer ${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`
    //         }
    //     }).then((res) => {
    //         setPageData(res.data)
    //         setTitle(res.data.title)
    //         setPageContent(parse((res.data.page_content)))
    //     })
    //         //   .then((res) => { ((res.data))})
    //         .catch((error) => { console.log(error) })
    // }

    const callSlugApi = async () => {
        
            const dataParams = {
                token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
                idtwo: idtwo
            }
            await axios.post(`${process.env.REACT_APP_DOMAIN}/call-dynamickb-two`, dataParams).then((res) => {
                setPageData(res.data)
                setTitle(res.data.title)
                setPageContent(parse((res.data.page_content)))
            }).catch((error) => { console.log(error) })
      
    }

    // const showMoreApi = () => {
    //     axios.get(`${process.env.REACT_APP_BLOGS_API}/knowledge-base-cms?url_slug=${idtwo}`)
    // }

    // console.log(pageData)

    useEffect(() => {
        window.scroll(0, 0)
        callSlugApi()
    }, [idtwo])

    return (
        <div>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link href="https://www.elitesiteoptimizer.com/public/assets/eso/images/eso-fav_icon.png" rel="icon" type="image/png" sizes="32x32" />
            <title>Back Link Data Vs Average Ranking Data | KnowledgeBase | Elite Site Optimizer</title>
            <link rel="canonical" href="https://www.elitesiteoptimizer.com/kb/back-link-data-vs-average-ranking-data/" />
            <meta name="description" content="Visual line graph and table format report that tracks back link data. It helps users to identify link building strategies to improve rank position " />
            <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            {/* facebook */}
            {/* twitter */}
            {/* linkedin */}
            {/* PAGE custom css   */}
            {/* PAGE custom jS   */}
            {/* PAGE Tracking code   */}
            {/*Google Tag Manager */}
            {/* Google Tag Manager */}
            {/* End Google Tag Manager */}
            {/*Google Analytics Code */}
            {/* Global site tag (gtag.js) - Google Analytics */}
            {/*Facebook Pixel Code */}
            {/* Facebook Pixel Code */}
            <noscript>
                &lt;img height="1" width="1"
                src="https://www.facebook.com/tr?id=553406781904038&amp;ev=PageView
                &amp;noscript=1" alt="Facebook"/&gt;
            </noscript>
            {/* End Facebook Pixel Code */}
            {/* custom_css and Js  */}
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/bootstrap.min.css" />
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/font-awesome.min.css" />
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/hfstyle.css" />

            {/* #main-header */}
            <div id="et-main-area">
                {/*Google Tag Manager (noscript) */}
                {/* Google Tag Manager (noscript) */}
                <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
                    height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
                {/* End Google Tag Manager (noscript) */}
                <div id="main-content" className="kb_knowledge_page top_padding_50 bottom_padding_50">
                    <div className="container">
                        <div id="content-area" className="row">
                            <div id="left-area" className="col-md-8 col-12">
                                {pageData.map((items, index) => {
                                    return (
                                        <h1 className="main_title bottom_padding_30">{items.title}</h1>
                                    )
                                })}

                                <div className="entry-content">
                                    <div className="pakb-header">
                                        <form role="search" id="kbsearchform" >
                                            <div className="pakb-search bottom_padding_30">
                                                <input type="hidden" name="_token" defaultValue="NY9GcHC4zPLnv4nn4CgCvi1qVxrVRjdtLXPZYb4c" />                                               
                                                <input value={sValue} onChange={handleChange} type="text" name="s" placeholder="Search" id="tags" className="autosuggest"
                                           onKeyPress={onFormsubmit} />
                                                 <span class="fa fa-search searchbutton"><input onClick={submit} id="searchsubmit" defaultValue="Search" /></span>
                                                <input type="hidden" name="post_type" defaultValue="knowledgebase" />
                                            </div>
                                        </form>
                                    </div>

                                    <article className="pakb-single">
                                        <div className="pakb-content">
                                            {pageData.map((items, index) => {
                                               
                                                var result_data="";
                                                var datavalue="";
                                                if(items.page_content.text !="" && items.page_content.text!=null){
                                                     datavalue=items.page_content.text;                                                
                                                     result_data = datavalue.replaceAll("[[BASEURL]]", "https://strapi.elitesiteoptimizer.com");
                                                   
                                                }
                                                return (
                                                    <div>
                                                        {(parse(result_data))}
                                                    </div>
                                                )
                                            })}
                                            {/* <h2>Backlink Data vs. Average ranking Data</h2>
                                            <ul>
                                                <li>This report provides users with the average ranking position of their domain in proportion to the backlinks at regular intervals.</li>
                                                <li>It also provides a comparison report highlighting the Backlink data and Average Ranking Data.</li>
                                                <li>The reports are available in both as a visual line graph and also as a table report to track the changes.</li>
                                                <li>It helps users to identify and correlate link building activities for better rank performance.</li>
                                            </ul>
                                            <div id="attachment_81193" className="wp-caption aligncenter" style={{ width: '1118px' }}><img className="wp-image-81193 size-full" src="https://www.elitesiteoptimizer.com/str/uploads/Backlink_Data_vs_Average_Ranking_Data_Report_2e5000b573.png" alt="Backlink Data vs. Average ranking Data Report" aria-describedby="caption-attachment-81193" />
                                                <p id="caption-attachment-81193" className="wp-caption-text">Backlink Data vs. Average ranking Data Report</p>
                                            </div> */}
                                            <p>&nbsp;</p>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div id="sidebar" className="col-md-4 col-12">
                                <div id="search-3" className="kb_widget kb_search">
                                    <form role="search" method="post" id="searchform" className="searchform" action="https://www.elitesiteoptimizer.com/kb/search/">
                                        <div>
                                            <input type="hidden" name="_token" defaultValue="NY9GcHC4zPLnv4nn4CgCvi1qVxrVRjdtLXPZYb4c" />                                        <label className="screen-reader-text" htmlFor="s">Search for:</label>
                                            <input type="text" defaultValue name="s" id="tags" />
                                            <input type="submit" id="searchsubmit" defaultValue="Search" />
                                        </div>
                                    </form>
                                </div>
                                <Categories />
                                <RecentPosts />
                                {/* end .kb_widget */}
                            </div>
                            {/* end #sidebar */}
                        </div>
                        {/* #content-area */}
                    </div>
                </div>
            </div>
            {/* #et-main-area */}
            {/* <div className="main-addon hide-overlay">
                <div className="overlay">
                    <div className="overlay-content">
                        <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
                        <iframe title="support" src="https://www.elitesiteoptimizer.com/addon/kb/" />
                    </div>
                </div>
                <div className="buttons-container">
                    <button className="button supportBTN"><i className="fa fa-search" />Support</button>
                </div>
            </div> */}
            {/*Start of Zendesk Chat Script*/}
            {/*End of Zendesk Chat Script*/}


            {/* #main-footer */}
            {/* #page-container */}
        </div>
    )
}

export default DynamicKbTwo;