import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { HashLink } from 'react-router-hash-link';
//import './Contactstyles.css';
import $ from 'jquery';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import bg_3 from '../../../images/eso/home_image/bg_3.png';
import bg_4 from '../../../images/eso/home_image/bg_4.png';

const ContactForm = () => {

    //Title Change
    useEffect(() => {
        document.title = 'Thank You | SEO Automation Tool';
        window.scroll(0, 0)
    }, []);


    //------------------------------------------------------------------------------
    const [value, setValue] = useState("")
    // const [isVerified, setIsVerified] = useState(false)

    const ContactURL = "/api/contactForm";

    const navigate = useNavigate();

    const Ref = useRef(null);
    const [loading, setLoading] = useState(false)

    const [checkBox, setCheckBox] = useState(false)
    const [captcha, setCaptcha] = useState(false)

    const [inputValues, setInputValue] = useState({
        name: "",
        email: "",
        phoneNo: "",
        message: "",

    });

    const { name, email, phoneNo, message } = inputValues

    const [validation, setValidation] = useState({
        name: "",
        email: "",
        phoneNo: "",
        message: "",
        recaptcha: "",
        checkbox: ""
    });

    const callCheckFunction = () => {
        setCheckBox(!checkBox)
    }

    const checkCaptcha = () => {
        setCaptcha(true)
    }

    const checkValidation = () => {
        let errors = { ...validation };

        //Name validation
        if ($('#name').val() === "") {
            errors.name = "This field is required.";
        } else if (!(/^[a-zA-Z]+$/g.test($('#name').val()))) {
            errors.name = "Please enter alphabet character only";
        } else {
            errors.name = "";
        }

        //Email validation
        if ($('#email').val() === "") {
            errors.email = "This field is required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test($('#email').val())) {
            errors.email = "Please enter valid Email address";
        } else {
            errors.email = "";
        }

        //Phone validation
        if ($('#phoneNo').val() === "") {
            errors.phoneNo = "This field is required.";
        }
        else if ($('#phoneNo').val().length < 10 || $('#phoneNo').val().length >= 15) {
            errors.phoneNo = "Please enter valid Phone number";
        }
        else {
            errors.phoneNo = "";
        }

        //Message validation
        if ($('#message').val() === "") {
            errors.message = "This field is required.";
        } else {
            errors.message = "";
        }

        //checkbox validation
        if ($('.checkbox').is(":checked")) {
            $('.checkbox').val('true');
        } else {
            $('.checkbox').val('false');
        }

        if ($('.checkbox').val() === 'false') {
            errors.checkbox = "This field is required.";
        } else {
            errors.checkbox = "";
        }

        //recaptcha validation
        // if ($('.recaptcha-checkbox').is(":checked")) {
        //     $('.recaptcha-checkbox').val('true');
        // } else {
        //     $('.recaptcha-checkbox').val('false');
        // }

        // if ($('.recaptcha-checkbox').val() === 'false') {
        //     errors.recaptcha = "This field is required.";
        // } else {
        //     errors.recaptcha = "";
        //     // alert("Thank u");
        // }

        if (captcha) {
            errors.recaptcha = "";
        } else {
            errors.recaptcha = "This field is required.";
        }

        return setValidation(errors);
    }


    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputValue({ ...inputValues, [name]: value });
        // checkValidation();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        Ref.current.focus();
        checkValidation();
        console.log("input-----", inputValues);
        if (name != '' && email != '' && phoneNo != '' && message != '' && checkBox && captcha) {
            setLoading(true)
            await axios.post(ContactURL, {
                email: inputValues.email,
                name: inputValues.name,
                phone: value + " " + inputValues.phoneNo,
                // subject: "test",
                message: inputValues.message,
            })
                .then((response) => {
                    console.log("Get.Data=====>", response)
                    //redirect logic
                    if (response.data.result == 200) {
                        navigate("/thankyou")
                    }
                })
                .catch(error => { console.log(error) })
            setLoading(false)
        } else {
            console.log("unfilled spaces, thows error")
        }
    }


    //------------------------------------------------------------------------------

    return (
        <div>
            {/* #main-header */}
            <div id="et-main-area">
                {/* Contact from */}
                <div className="container" id="contact_form_test">
                    <div className="contact_title_wrap">
                        <h2>Thank you!<br/> <span>You can check your email for a detailed report</span></h2>
                    </div>
                    <br/>
                    {/* <form className="contact_form_outer_wrap" id="contact_form" onSubmit={handleSubmit} autoComplete="off">
                        <input type="hidden" name="_token" defaultValue="76cxPcH6SkGPN0WvfVloDHkxTVdYfWbmTj3907hE" />
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="form-group">
                                    <input type="text" className="form-control cl_contact clyour_name" id="name" placeholder="Your Name *" name="name"
                                        onChange={(e) => handleChange(e)}
                                        value={inputValues.name} ref={Ref} />
                                    <span className='text-error'>{validation.name}</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="form-group">
                                    <input type="email" className="form-control cl_contact cl_email" id="email" placeholder="Your Email *" name="email"
                                        onChange={(e) => handleChange(e)}
                                        value={inputValues.email} />
                                    <span className='text-error'>{validation.email}</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="form-group">
                                    <PhoneInput
                                        international
                                        defaultCountry="US"
                                        value={value}
                                        onChange={setValue}
                                        className="icon-flag"
                                    />
                                    <input type="number" name="phoneNo" id="phoneNo" placeholder="Phone Number *" className='phone-no-field' step="0.01" value={inputValues.phoneNo} onChange={(e) => handleChange(e)} />
                                    <span className='text-error'>{validation.phoneNo}</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="form-group">
                                    <textarea type="text" className="form-control textarea" id="message" placeholder="Your Message *" name="message"
                                        value={inputValues.message} onChange={(e) => handleChange(e)} />
                                    <span className='text-error'>{validation.message}</span>
                                 
                                </div>
                            </div>
                            <div className="form-element-section contact_full contact_us_terms_condition">
                                <label htmlFor="fm_privacy_policy6" className="ctform-label privacy-unchecked">
                                    <input id="fm_privacy_policy6" name="checkbox" className="wd-flex-row fm-gdpr-checkbox checkbox" type="checkbox"
                                        value={checkBox} onClick={callCheckFunction} />
                                    {console.log('checkbox status: ' + checkBox)}
                                    I consent to have this website store my submitted information so they can respond to my inquiry (<HashLink to="/terms-conditions/" target="_blank" style={{ color: "blue" }}>Terms &amp; Conditions</HashLink> and  <HashLink to="/privacy-policy/" target="_blank" style={{ color: "blue" }}>Privacy Policy</HashLink>)</label>
                                <span className='text-err checkbox_cus'>{validation.checkbox}</span>
                            </div>
                            <div className="pt-3 recaptcha-checkbox ">
                                <ReCAPTCHA
                                    sitekey="6LdkrQwkAAAAAOGkFoji7cLJxhE9NnOVSZEI_GEf"
                                    name="recaptcha"
                                    className="recaptcha-checkbox"
                                    value={captcha}
                                    onChange={checkCaptcha}
                                />
                                {console.log('captcha status: ' + captcha)}
                                <span className='text-err recaptcha'>{validation.recaptcha}</span>
                            </div>
                        </div>
                        <div className="common_center contact_full">
                            <button type="submit" className="contact_form_submit_btn_wrap">
                                {loading && <span>Submit</span>}
                                {loading && (<i className="fa fa-spinner fa-spin" />)}
                                {!loading && <span>Submit</span>}
                            </button>
                        </div>
                    </form> */}
                </div>
            </div>
            {/* End Contact Form */}
            {/* CTA */}
            <div className="cta_bg_wrap">
                <div className="cta_bg_img_left_outer_wrap">
                    <img src={bg_4} alt="CTA_bg" />
                </div>
                <div className="cta_bg_img_right_outer_wrap">
                    <img src={bg_3} alt="CTA_bg" />
                </div>
                <div className="container">
                    <div className="cta_content_outer_wrap">
                        <h4><span>Let’s Get Started,</span><br />Contact The Experts <br />And Boost Your SEO Today!</h4>
                        {/*  <a href="#">Request Free Trial</a> */}
                    </div>
                </div>
            </div>
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/plugin/select2/css/select2.min.css" type="text/css" media="all" />
            {/* #et-main-area */}
            <div className="main-addon hide-overlay">
                <div className="overlay">
                    <div className="overlay-content">
                        <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
                        <iframe title="support" src="https://www.elitesiteoptimizer.com/addon/kb/" />
                    </div>
                </div>
                <div className="buttons-container">
                    <button className="button supportBTN"><i className="fa fa-search" />Support</button>
                </div>
            </div> {/*Start of Zendesk Chat Script*/}
            {/*End of Zendesk Chat Script*/}{/*'}'*/}
            {/* #main-footer */}
            {/* #page-container */}
        </div>
    );
}

export default ContactForm;