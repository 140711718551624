import React, { useEffect } from 'react';
import axios from 'axios'

const TokenGenerator = () => {

    const callingTokenGenerator = async() => {
       await axios.post(`${process.env.REACT_APP_DOMAIN}/strapi-token-generator`).then((res) => {console.log(res.data)})
    }

    useEffect(() => {
        callingTokenGenerator()
    }, [])

    return (
        <div>
            Token updated.
        </div>
    )
}

export default TokenGenerator;