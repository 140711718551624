import React, { useEffect } from 'react';
import './ForgetPassword.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import eso_logo from './../../../../images/logo/eso-logo.png';
import { HashLink } from 'react-router-hash-link';

const ForgetPassword = () => {
    useEffect(() => {
        document.title = 'Elite Site Optimizer - Password Reset | Elite Site Optimizer';
    }, []);
    return (
        <div id="login-page" className="theme-turquoise pace-done">
            <header id="login-header_cus" className="login-header_cus">
                <div id="login-logo_cus">
                    <HashLink to="/" elementId='root'><img src={eso_logo} alt="SEO Automation Tool" /></HashLink>
                </div>
            </header>
            <div className="container_cus">
                <div className="row_cus">
                    <div className="col-xs-12_cus">
                        <div id="login-box_cus">
                            <div id="login-box-holder_cus">
                                <div className="row_cus">
                                    <div className="col-xs-12_cus">
                                        <div id="login-box-inner_cus" className="with-heading_cus new-login-box-inner_cus">
                                            <header id="login-header_cus">
                                                <div id="login-logo_cus" style={{ whiteSpace: 'nowrap', textAlign: 'center', fontWeight: '300', padding: '15px 80px 15px 0px', display: 'block', fontSize: '30px' }}>Forgot your password?<hr style={{ borderTop: '1px solid lightgray', width: '10em' }} className="hr" /></div>
                                            </header>
                                            <div>
                                                Enter your email address to recover your password.
                                            </div>
                                            <form role="form" method="post" action=".">
                                                <div style={{ display: 'none' }}><input type="hidden" name="csrfmiddlewaretoken" defaultValue="ARjYmoJrqxcMAZQwMXqmvO0ByXXDn90J" /></div>
                                                <div className="input-group_cus reset-pass-input_cus col-xs-12_cus">
                                                    <label htmlFor="username">Email</label>
                                                    <input className="form-control_cus" name="email" type="text" placeholder="Email address" />
                                                </div>
                                                <div className="row_cus">
                                                    <div className="col-xs-12_cus">
                                                        <button type="submit" className="btn_cus btn-success_cus col-xs-12_cus">Reset password</button>
                                                    </div>
                                                    <div style={{ paddingBottom: '25px' }} className="col-xs-12_cus">
                                                        <HashLink className="btn_cus btn-success_cus col-xs-12_cus" to="/" elementId='root' id="reset_login_cus">Click to login</HashLink>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword;