import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Categories = () => {

    const kblistapi = `${process.env.REACT_APP_BLOGS_API}/kb-lists`
    const [listData, setlistData] = useState([])
    const [KbCmsData, setKbCmsData] = useState([])

    // const callKBapi = async () => {
    //     await axios.get(kblistapi, {
    //         headers: {
    //             Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAsImlhdCI6MTY3NTE0ODQ5NiwiZXhwIjoxNjc3NzQwNDk2fQ.NW2cdbJDXHoo3FbqlOQTvyRtnZf0gBePqHv9bMo06zw'
    //         }
    //     }).then((res) => {
    //         setlistData(res.data)
    //         setKbCmsData(res.data)
    //     })
    // }

    const callKBapi = async () => {
       
            const dataParams = {
                token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
            }
            await axios.post(`${process.env.REACT_APP_DOMAIN}/call-kb-categories`, dataParams).then((res) => { 
                setlistData(res.data)
                setKbCmsData(res.data)
             }).catch((error) => { console.log(error) })
     
    }

    // console.log("Data", KbCmsData)
    useEffect(() => {
        window.scroll(0, 0)
        callKBapi()
    }, [])



    return (
        <div>

            <div id="categories-2" className="kb_widget kb_categories bottom_padding_50 clearfix">

                <h3 className="widgettitle">CATEGORIES</h3>
                {listData.map((items, index) => {
                    const array = [items.kb_cms.length];
                    const length = array;
                    return (  
                        <ul className="bgs-count-color">
                            <li className="cat-item cat-item-1">

                            <a href={`${process.env.REACT_APP_DOMAIN}/kb/${items.url_slug}`}   title={items.title}> <i className={items.icon} /> &nbsp;
                                     {items.name} <span className="bgs-count bgs-cat bgs-cat-43">{length}</span>
                                    </a >
                            </li>
                        </ul>
                    )
                })}
            </div>

        </div>
    )
}

export default Categories;