import React from 'react';
import { HashLink } from 'react-router-hash-link';
import callIcon from './../../images/icons/call-icon.png';
import mailIcon from './../../images/icons/mail-icon.png';
import './footer.css';
import dmca from './../../images/str/uploads/DMCA.png'
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CookiesPopup from "../../components/CookiesPopup/CookiesPopup";
import '@fortawesome/fontawesome-free/css/all.min.css';
const Footer = () => {
    useEffect(() => { 
        document.getElementById("navbarSupportedContent").classList.remove("show");
    });

    return (
        <div>
            <footer className="footer_bg_outer_wrap">
                <div className="container"> {/* End Contact Detail */}
                    {/* Page Url */}
                    <div className="footer_page_link_outer_wrap">
                        {/* <ul>
                            <h4>Solutions</h4>
                            <li> <Link to="/solutions/ecommerce-business/" state={{ from: `ecommerce-business` }} elementId='root'>Ecommerce Business</Link> </li>
                            <li> <Link  to="/solutions/restaurants-seo/" state={{ from: `restaurants-seo` }} elementId='root' >Restaurant SEO</Link> </li>
                            <li> <Link  to="/solutions/digital-marketing/" state={{ from: `digital-marketing` }} elementId='root'>Digital Marketing</Link> </li>
                            <li> <Link  to="/solutions/enterprise-brands/" state={{ from: `enterprise-brands` }}elementId='root' >Enterprise Brands</Link> </li>
                            <li> <Link  to="/solutions/b2g-seo/" state={{ from: `b2g-seo` }} elementId='root'>B2G SEO</Link> </li>
                            <li> <Link to="/solutions/personal-bloggers/"  state={{ from: `personal-bloggers` }} elementId='root'>Personal Bloggers</Link> </li>
                            <li> <Link  to="/solutions/news-content-publishers/" state={{ from: `news-content-publishers` }} elementId='root'>News &amp; Content Publishers</Link> </li>
                            <li> <Link  to="/solutions/web-hosting/" state={{ from: `web-hosting` }} elementId='root'>Web Hosting Seo Toolkit</Link> </li>
                        </ul> */}

                        <ul>
                            <h4>Features</h4>
                            <li> <Link to="/features/seo-dashboard/" state={{ from: `ecommerce-business` }} elementId='root'>SEO Dashboard</Link> </li>
                            <li> <Link to="/features/webpage-seo-analysis/" state={{ from: `ecommerce-business` }} elementId='root'>Performance Analysis</Link> </li>
                            <li> <Link to="/features/keyword-ranking-checker/" state={{ from: `ecommerce-business` }} elementId='root'>Rank Analysis</Link> </li>
                            <li> <Link to="/features/on-page-seo-analysis/" state={{ from: `ecommerce-business` }} elementId='root'>On Page SEO Analysis</Link> </li>
                            <li> <Link to="/features/internal-link-checker/" state={{ from: `ecommerce-business` }} elementId='root'>Link Checker</Link> </li>
                            <li> <Link to="/features/schema-validator/" state={{ from: `ecommerce-business` }} elementId='root'>Schema Validator</Link> </li>
                            <li> <Link to="/features/traffic-analytics/" state={{ from: `ecommerce-business` }} elementId='root'>Analytics Console</Link> </li>
                            <li> <Link to="/features/sitemap-generator/" state={{ from: `ecommerce-business` }} elementId='root'>Sitemap Generator</Link> </li>
                            <li> <Link to="/features/content-audit/" state={{ from: `ecommerce-business` }} elementId='root'>Content Audit</Link> </li>
                            <li> <Link to="/features/plagiarism-checker/" state={{ from: `ecommerce-business` }} elementId='root'>Plagiarism Checker</Link> </li>
                            <li> <Link to="/features/accessibility-checker/" state={{ from: `ecommerce-business` }} elementId='root'>Accessbility Checker</Link> </li>

                            

                        </ul>
                        {/* end .fwidget */}
                        <div className="footer_page_like_section_wrap" style={{marginLeft:"172px"}}> 
                            <ul>
                                <h4>About Us</h4>
                                <li> <HashLink to="/terms-conditions/" elementId='root'>Terms &amp; Conditions</HashLink> </li>
                                <li> <HashLink to="/privacy-policy/" elementId='root'>Privacy Policy</HashLink> </li>
                                <li> <HashLink to="/disclaimer/" elementId='root'>Disclaimer</HashLink> </li>
                                <li> <HashLink to="/about-us/" elementId='root'>About us</HashLink> </li>
                            </ul>
                            <ul className="footer_location_outer_wrap">
                                <h4>Location</h4>                     
                                <li> <a>Elite Site Optimizer.<br /> </a> </li>                     
                                <li> <a> 6G Auer Ct.<br /> East Brunswick, NJ 08816<br /> </a> </li></ul>
                        </div>  
                        <div className="footer_page_social_wrap">
                            <ul>
                                <h4>Resources</h4>
                                <li> <HashLink to="/blogs/" elementId='root'>Blog</HashLink> </li>
                                <li> <HashLink to="/site-map/" elementId='root'>Site Map</HashLink> </li>
                                {/* <li> <HashLink to="/kb/"  elementId='root'>Knowledgebase</HashLink> </li> */}
                            </ul>
                            <ul className="footer_page_social_inner_wrap">
                                <h4>Follow Us</h4>
                                <li>
                                    <a href="https://www.facebook.com/elitesiteoptimizer" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                                        <i className="fa fa-facebook-f" aria-hidden="true"></i>
                                    </a>

                                    <a href="https://twitter.com/eliteseomanager" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                                        <i className="fa fa-twitter" aria-hidden="true"></i>
                                    </a>

                                    <a href="https://www.instagram.com/elitesiteoptimizer/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                                        <i className="fa fa-instagram" aria-hidden="true"></i>
                                    </a>
                                    
                                    <a href="https://www.linkedin.com/company/elite-site-optimizer" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCCdvvASrYRheEzAnbinh_Ow/videos" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
                                        <i className="fa fa-youtube-play" aria-hidden="true"></i>
                                    </a>
                                </li>  
                            </ul> 
                            <div className="dmca_img_outer_wrap">
                                {/* <img src={dmca} alt="DMCA.com Protection Status" align="DMCA" /> */}
                            </div>
                        </div>
                    </div>
                    {/*solution menu*/}        {/* End Page Url */}
                    {/* Bottom bar */}
                    <div className="bottom_bar_outer_wrap">
                        {/* <p>Elite mCommerce®, Module Bazaar®, and Elite Site Optimizer® are the trademarks and brand names of eGrove Systems Corporation. All other trademarks, service marks, website names, product names, logos, brands, other marks or similar rights featured or referred to, within this website are the property of their respective trademark holders. eGrove has no affiliation with those trademark holders.</p> */}
                        <p>All Rights Reserved. Copyright © 2015 -2023 | <a href="#">Elite Site Optimizer</a></p>
                    </div>
                    {/* End Bottom bar */}
                </div>
            </footer>
            <CookiesPopup/>
        </div>

        
                    //  <div className="footer_contact_detail_outer_wrap">
                    //     <div className="footer_contact_detail_inner_wrap">
                    //         <div className="footer_contact_icon_wrap"> <img src={callIcon} alt='footer-call' width="auto" height="auto" />
                    //         </div>
                    //         <div id="content1" className="footer_contact_icon_content_wrap">
                    //             <h5 className='contact1'>&nbsp;&nbsp;Call Us At</h5>
                    //             <HashLink className="contact1 telliphone" to="tel:+17323072655">&nbsp; +1 (732) 307 2655</HashLink>
                    //         </div>
                    //     </div>
                    //     <div id="content2" className="footer_contact_detail_inner_wrap">
                    //         <div className="footer_contact_icon_wrap"> <img src={mailIcon} alt='footer-mail' width="auto" height="auto" /> </div>
                    //         <div className="footer_contact_icon_content_wrap">
                    //             <h5 className='contact2'>&nbsp;&nbsp;Mail Us For Queries</h5> <HashLink className="email-footer contact2" to="mailto:sales@elitesiteoptimizer.com" target="_blank" rel="noopener noreferrer">&nbsp;
                    //                 sales@elitesiteoptimizer.com
                    //             </HashLink> </div>
                    //     </div>
                    // </div>        
    )
}

export default Footer;
