import React , { useState, useEffect } from "react";
import axios from 'axios';
import {Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Sidebar from "./Sidebared.js";
import './Dashboard.css' 
import { MDBDataTable } from 'mdbreact';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';


const DatatablePage = () => {
    const [cardData, setCardData] = useState([])
    const [loading, setLoading] = useState(false)
    const[fromdate,setfromdate] =useState("")
    const[todate,settodate] =useState("")
    const[errmsg,setErrmsg] =useState("")


const exportapiurl=`${process.env.REACT_APP_API_URL }/instantuserexport/?from_date=${fromdate}&to_date=${todate}`
    useEffect(() => {   
        callUserdataApi()    
    }, [])
 

     const onFromchange = (event) => {
    setfromdate(event.target.value);
    
  }
  const onTochange = (event) => {
    if(fromdate < event.target.value)
    settodate(event.target.value);
  }
  const handleSubmit = async(event) => {
    setErrmsg("")
    setLoading(true)
    event.preventDefault();
    if(fromdate=="")
    {
      setErrmsg("From Date is required")
      return false;
    }
    if(todate=="")
    {
      setErrmsg("To Date is required")
      return false;
    }
    if(fromdate > todate){
      setErrmsg("Please Enter Valid Error Message")
      return false;
    }
    
    var filterurl=`${process.env.REACT_APP_DOMAIN}/call-userData-api`
    const datavalue= await axios.post(filterurl,{fromdate:fromdate,todate:todate})
    setLoading(false)
    setCardData(datavalue.data.data);
        
  };

  const resetClick = () =>{
    window.location.reload(false);

  }
  const handleClick = () => {
    setErrmsg("")
    if(fromdate =="")
    {
      setErrmsg("From Date is required")
      return false;
    }
    if(todate =="")
    {
      setErrmsg("To Date is required")
      return false;
    }

    if(fromdate > todate){
      setErrmsg("Please Enter Valid Error Message")
      return false;
    }
    window.open(exportapiurl, "_blank");
     callUserdataApi()
  };

    const callUserdataApi = async () => {   
        setLoading(true)
        var filterurl=`${process.env.REACT_APP_DOMAIN}/call-userData-api`
        setfromdate("")
        settodate("")
      const datavalue= await axios.post(filterurl,{fromdate:fromdate,todate:todate})
        setCardData(datavalue.data.data);
        setLoading(false)
    }

  const data = {
    columns: [
     
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Site Url',
        field: 'site_url',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Date',
        field: 'created_date',
        sort: 'asc',
        width: 150
      }
    ],
    rows:cardData
  };

  return (

    <section className="menu-table tog_menu_table_exit">
      <div className="user">
        <div className="user5">
        <h3>User list</h3>

        <div className="link-set">
          <div className="link">
            <a href="">
              Home <span> &gt;</span>{" "}
            </a>
            <a href="" className="active">
              {" "}
              User list
            </a>
          </div>
        
        </div>

        </div>
        <div>
        <form className="us_fm_outer_wrap" onSubmit={handleSubmit}>
           <div className="us_fm_outer_wrap">   
            <label>From Date:</label>   
             <input type="date" className="us_li_fm_date" value={fromdate}  onChange={onFromchange} />
              <label className="us_fm_to_lbl">To Date:</label>
              <input type="date" className="us_li_to_date" value={todate}  onChange={onTochange}/>
              </div>
              <div className="ft_et_wrap">
              <button type="submit">Filter</button>
              <button type="button" onClick={handleClick} >Export</button>
              <button type="button" onClick={resetClick} >Reset</button>
             
              </div>
              </form>
              <p className="err_message Date_err" >{errmsg}</p>
           </div>
        <div className="tset">
              <MDBDataTable 
    theadColor='#454f71'
    small
    responsive 
    bordered
    hover
    noBottomColumns
    exportToCSV 
    data={data}
    />
         

        </div>
       
      </div>
    </section>
   

   
  );
}


  export default DatatablePage