import React, { useState, useEffect, lazy, Suspense } from 'react';
import './App.css';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';
import Home from './pages/Home/Home';
import { RotatingLines } from 'react-loader-spinner';
import PerformanceBasedSEO from './pages/Services/DigitalMarketing/Performance/PerformanceBasedSEO';
import CustomCrawler from './pages/Services/CustomCrawler/CustomCrawler';
import Zendesk, { ZendeskAPI } from "./ZendexConfig";



import Blog from './pages/Blog/Blog';
// import search from './pages/Blog/search';
import WhiteLabelPartner from './pages/BecomeAPartner/WhiteLabelPartner';
import SolutionPartner from './pages/BecomeAPartner/SolutionPartner';
import WhiteLabelPartnerForm from './pages/BecomeAPartner/WhiteLabelPartnerForm';
import SolutionPartnerForm from './pages/BecomeAPartner/SolutionPartnerForm';

import CookiesPopup from './components/CookiesPopup/CookiesPopup';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import OldPrivacyPolicy from './pages/PrivacyPolicy/OldPrivacyPolicy';
import Login from './pages/Accounts/Login/Login';
// import Logout from './pages/Accounts/Login/ForgetPassword/ForgetPassword';
import ForgetPassword from './pages/Accounts/Login/ForgetPassword/ForgetPassword';
// import EcommerceBusiness from './pages/solutions/EcommerceBusiness';
// import RestaurantsSeo from './pages/solutions/RestaurantsSeo';
// import DigitalMarketingService from './pages/solutions/DigitalMarketingService';
// import EnterpriseBrands from './pages/solutions/EnterpriseBrands';
// import WebHosting from './pages/solutions/WebHosting';
// import NewsContentPublisher from './pages/solutions/NewsContentPublisher';
// import PersonalBloggers from './pages/solutions/PersonalBloggers';
// import B2gSeo from './pages/solutions/B2gSeo';
import Signup from './pages/Accounts/Login/Signup/Signup';

import BlogForm from './pages/Blog/BlogForm';
import Errorpage from './pages/Errorpage/Errorpage';
import DynamicBlogs from './pages/Blog/DynamicBlogs';
import MobileSeo from './pages/Services/Services/MobileSeo';
import AppStoreOptimization from './pages/Services/Services/AppStoreOptimization';
import TermsConditions from './pages/FooterAboutus/TermsConditions';
import Disclaimer from './pages/FooterAboutus/Disclaimer';
import SiteMap from './pages/FooterResources/SiteMap';
import Thankyou from './pages/ContactUs/Thankyou';
import CountryErrorpage from './pages/Errorpage/Countryerrorpage.js';


import ContactForm from './pages/InstantAuditFeatures/ContactForm/ContactForm';
// --------------------------------------------------------------------------Updated codes-----------------------------------------------------------------------
import InstantAuditFeatures from './pages/InstantAuditFeatures/InstantAuditFeatures/InstantAuditFeatures';
import AccessValidation from './pages/InstantAuditFeatures/AccessValidation/AccessValidation';
import TrialReport from './pages/InstantAuditFeatures/trial-report/TrialReport';
import InstantAuditFooter from './components/InstantAuditFooter/InstantAuditFooter';
// -------------------------------------------------------------------------End of Updated codes ------------------------------------------------------------------
// import { useNavigate } from 'react-router-dom';
// import AmpHtml from './pages/AmpHtml';
import KnowledgeBase from './pages/kb/KnowledgeBase';
// import SignupKb from './pages/kb/sign-up/SignupKb';
// import AccountActiviationKb from './pages/kb/account-activation/AccountActivationKb';
// import AccountLoginKb from './pages/kb/account-login/AccountLoginKb';
// import FreeTrialKb from './pages/kb/free-trial/FreeTrialKb';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import Topics from './pages/Blog/Topics';
import DynamicKb from './pages/kb/DynamicKb';
import DynamicKbTwo from './pages/kb/DynamicKbTwo';
import SearchKb from './pages/kb/SearchKb';
// import DynamicsFeatures from './pages/Features/DynamicFeatures';
import TokenGenerator from './components/TokenGenerator';
import Users from './pages/Backend/admin/Userspage';
import Contactview from './pages/Backend/admin/Contactview';
import Whitelabelview from './pages/Backend/admin/Whitelabelview';
import Solutionlabelview from './pages/Backend/admin/Solutionlabelview';
import SolutionlabelForm  from './pages/Backend/admin/SolutionlabelForm';
import WhitelabelForm from './pages/Backend/admin/WhitelabelForm';
import Dashboard from './pages/Backend/admin/Dashboard';
import ContactList from './pages/Backend/admin/ContactForm';
import ErrorDb from './pages/Backend/admin/ErrorDb';
// import LandingPage from "./components/LandingPage/LandingpageCompine";
// import InstandMailResponse from "./components/LandingPage/Instand/checkMail.js";
// import OTPValidation from "./components/LandingPage/Instand/OTPValidation.js";
// import BusineesData from "./components/LandingPage/Instand/BusinessInformation.js";






// normal imports of lazy loaders:

// import About from './pages/About/About';
// import Services from './pages/Services/Services/Services';
// import DigitalMarketing from './pages/Services/DigitalMarketing/DigitalMarketing/DigitalMarketing';
// import ContactUs from './pages/ContactUs/ContactUs';
// import Features from './pages/Features/Features/Features.js';
// import SEODashboard from './pages/Features/SEODashboard/SEODashboard';
// import PerformanceAnalysis from './pages/Features/PerformanceAnalysis/PerformanceAnalysis';
// import RankAnalysis from './pages/Features/RankAnalysis/RankAnalysis';
// import OnPageSeoAnalysis from './pages/Features/OnPageSeoAnalysis/OnPageSeoAnalysis';
// import LinkChecker from './pages/Features/LinkChecker/LinkChecker';
// import AnalyticsConsole from './pages/Features/AnalyticsConsole/AnalyticsConsole';
// import SchemaValidator from './pages/Features/SchemaValidator/SchemaValidator';
// import SitemapGenerator from './pages/Features/SitemapGenerator/SitemapGenerator';
// import ContentAudit from './pages/Features/ContentAudit/ContentAudit';
// import PlagiarismChecker from './pages/Features/PlagiarismChecker/PlagiarismChecker';
// import DynamicSolutions from './pages/solutions/DynamicSolutions';

// lazy loaders implementation:

const About = lazy(() => import('./pages/About/About'));
const Services = lazy(() => import('./pages/Services/Services/Services'));
const DigitalMarketing = lazy(() => import('./pages/Services/DigitalMarketing/DigitalMarketing/DigitalMarketing'));
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs'));
const SchemaValidator = lazy(() => import('./pages/Features/SchemaValidator/SchemaValidator'));
const PlagiarismChecker = lazy(() => import('./pages/Features/PlagiarismChecker/PlagiarismChecker'));
const ContentAudit = lazy(() => import('./pages/Features/ContentAudit/ContentAudit'));
const SitemapGenerator = lazy(() => import('./pages/Features/SitemapGenerator/SitemapGenerator'));
const Features = lazy(() => import('./pages/Features/Features/Features.js'));
const Accessbility = lazy(() => import('./pages/Features/Accessbility/Accessbility'));
const SEODashboard = lazy(() => import('./pages/Features/SEODashboard/SEODashboard'));
const PerformanceAnalysis = lazy(() => import('./pages/Features/PerformanceAnalysis/PerformanceAnalysis'));
const RankAnalysis = lazy(() => import('./pages/Features/RankAnalysis/RankAnalysis'));
const OnPageSeoAnalysis = lazy(() => import('./pages/Features/OnPageSeoAnalysis/OnPageSeoAnalysis'));
const LinkChecker = lazy(() => import('./pages/Features/LinkChecker/LinkChecker'));
const AnalyticsConsole = lazy(() => import('./pages/Features/AnalyticsConsole/AnalyticsConsole'));
const DynamicSolutions = lazy(() => import('./pages/solutions/DynamicSolutions'));
const Loginform= lazy(() => import('./pages/Backend/Login/Loginform'));
const Sidebar= lazy(() => import('./pages/Backend/admin/Dashboard'));



//const Users= lazy(() => import('./pages/Backend/admin/Userspage'));

const handleLoaded = () => {
  ZendeskAPI("messenger", "open");
};

const App = () => {
 
    const sessionVal=sessionStorage.getItem("email");

  return (
    <>
        <Zendesk defer zendeskKey="60162c78-0a39-4b6e-986a-8c0be49e0219" onLoaded={handleLoaded} />

  

    <Router>
      <Suspense fallback={<RotatingLines
        style={{
          position: 'absolute', 
          left: '50%', 
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        strokeColor="#052A53"
        strokeWidth="5"
        animationDuration="0.75"
        width="45"
        visible={true}
      />}>
        <Routes>
          <Route exact path='/' element={<><NavBar /><Home /><Footer /></>} />
          <Route path='/about-us/' element={<><NavBar /><About /><Footer /></>} />
          <Route path='/services/' element={<><NavBar /><Services /><Footer /></>} />
          <Route path='/services/digital-marketing-services/' element={<><NavBar /><DigitalMarketing /><Footer /></>} />
          <Route path='/services/digital-marketing-services/performance-based-seo/' element={<><NavBar /><PerformanceBasedSEO /><Footer /></>} />
          <Route path='/services/custom-crawler/' element={<><NavBar /><CustomCrawler /><Footer /></>} />
          <Route path='/features/' element={<><NavBar /><Features /><Footer /></>} />
          {/* <Route path='/features/:id' element={<><NavBar /><DynamicsFeatures /><Footer /></>} /> */}
          <Route path='/features/accessibility-checker/' element={<><NavBar /><Accessbility /><Footer /></>} />

          <Route path='/features/seo-dashboard/' element={<><NavBar /><SEODashboard /><Footer /></>} />
          <Route path='/features/webpage-seo-analysis/' element={<><NavBar /><PerformanceAnalysis /><Footer /></>} />
          <Route path='/features/keyword-ranking-checker/' element={<><NavBar /><RankAnalysis /><Footer /></>} />
          {/* <Route path='/solutions/digital-marketing/' element={<><NavBar /><DigitalMarketingService /><Footer /></>} /> */}
          <Route path='/features/on-page-seo-Analysis/' element={<><NavBar /><OnPageSeoAnalysis /><Footer /></>} />
          <Route path='/features/internal-link-checker/' element={<><NavBar /><LinkChecker /><Footer /></>} />
          <Route path='/features/traffic-analytics/' element={<><NavBar /><AnalyticsConsole /><Footer /></>} />
          <Route path='/features/schema-validator/' element={<><NavBar /><SchemaValidator /><Footer /></>} />
          <Route path='/features/sitemap-generator/' element={<><NavBar /><SitemapGenerator /><Footer /></>} />
          <Route path='/features/content-audit/' element={<><NavBar /><ContentAudit /><Footer /></>} />
          <Route path='/features/plagiarism-checker/' element={<><NavBar /><PlagiarismChecker /><Footer /></>} />
          {/* <Route path='/features/:id' element={<><NavBar /><DynamicsFeatures /><Footer /></>} />  */}
          <Route path='/white-label-partner/' element={<><NavBar /><WhiteLabelPartner /><Footer /></>} />
          <Route path='/solution-partner/' element={<><NavBar /><SolutionPartner /><Footer /></>} />
          <Route path='/whitelabel-partner-form/' element={<><NavBar /><WhiteLabelPartnerForm /><Footer /></>} />
          <Route path='/solution-partner-form/' element={<><NavBar /><SolutionPartnerForm /><Footer /></>} />
          {/* <Route path='/features/plagiarism-checker/' element={<><NavBar /><PlagiarismChecker /><Footer /></>} /> */}
          <Route path='/contact-us/' element={<><NavBar /><ContactUs /><Footer /></>} />
          <Route path='/privacy-policy/' element={<><NavBar /><PrivacyPolicy /><Footer /></>} />
          <Route path="/privacy-policy-old/" element={<><NavBar /><OldPrivacyPolicy /><Footer /></>} />
          {/* <Route path='/accounts/login/' element={<Login />} /> */}
          <Route path='/accounts/password-reset/' element={<ForgetPassword />} />
          <Route path='/plans-and-pricing/' element={<><NavBar /><Signup /><Footer /></>} />
          {/* <Route path='/solutions/ecommerce-business/' element={<><NavBar /><EcommerceBusiness /><Footer /></>} />
        <Route path='/solutions/restaurants-seo/' element={<><NavBar /><RestaurantsSeo /><Footer /></>} />
        <Route path='/solutions/digital-marketing/' element={<><NavBar /><DigitalMarketingService /><Footer /></>} />
        <Route path='/solutions/enterprise-brands/' element={<><NavBar /><EnterpriseBrands /><Footer /></>} />
        <Route path='/solutions/web-hosting/' element={<><NavBar /><WebHosting /><Footer /></>} />
        <Route path='/solutions/news-content-publishers/' element={<><NavBar /><NewsContentPublisher /><Footer /></>} />
        <Route path='/solutions/personal-bloggers/' element={<><NavBar /><PersonalBloggers /><Footer /></>} /> */}
          {/* <Route path='/solutions/b2g-seo/' element={<><NavBar /><B2gSeo /><Footer /></>} /> */}
          <Route path='/blogs/' element={<><NavBar /><Blog /><Footer /></>} />
          <Route path='/blogform/' element={<><BlogForm /></>} />
          <Route path='/adminlogin/' element={<><Loginform /></>} />
          <Route path='/error-page/' element={<><CountryErrorpage /></>} />
          {/* <Route path='/blog/7-ways-seo-and-ppc-can-work-together-for-staffing-agencies-in-2022/' element={<><NavBar /><WaysSeo /><Footer /></>} />
        <Route path='/blog/why-seo-is-important-for-staffing-agencies/' element={<><NavBar /><StaffingAgencies /><Footer /></>} />
        <Route path='/blog/website-speed-optimization/' element={<><NavBar /><WebsiteSpeedOptimization /><Footer /></>} />
        <Route path='/blog/bing-webmaster-tools-guide/' element={<><NavBar /><BingWebmasterTools /><Footer /></>} />
        <Route path='/blog/google-adwords-for-beginners/' element={<><NavBar /><GoogleAdWords /><Footer /></>} />
        <Route path='/blog/facebook-lead-generation-tips/' element={<><NavBar /><Facebooklead /><Footer /></>} />
        <Route path='/blog/impact-do-meta-and-title-tags-have-on-the-search-engine-rankings/' element={<><NavBar /><ImpactDoMeta /><Footer /></>} />
        <Route path='/blog/content-audit-checklist-2022/' element={<><NavBar /><AContentAudit /><Footer /></>} />
        <Route path='/blog/email-marketing-guide/' element={<><NavBar /><EmailMarketing /><Footer /></>} />
        <Route path='/blog/shopify-vs-woocommerce-which-is-better-for-seo/' element={<><NavBar /><ShopifyVsCommerce /><Footer /></>} />
        <Route path='/blog/what-is-affiliate-marketing-and-how-to-do-it-effectively/' element={<><NavBar /><AffiliateMarketing /><Footer /></>} />
        <Route path='/blog/top-9-ppc-trends-to-follow-in-2022/' element={<><NavBar /><PpcTrends /><Footer /></>} />
        <Route path='/blog/an-unconventional-guide-to-seo-especially-for-staffing-agencies/' element={<><NavBar /><UniconventionalGuide /><Footer /></>} />
        <Route path='/blog/the-9-most-important-steps-staffing-agencies-must-perform-to-solving-low-rankings/' element={<><NavBar /><SolvingLowRanking /><Footer /></>} />
        <Route path='/blog/13-common-technical-seo-issues-how-to-fix-them/' element={<><NavBar /><CommonTechnical /><Footer /></>} />
        <Route path='/blog/sitemap-best-practices-to-improve-SEO/' element={<><NavBar /><Sitemap /><Footer /></>} />
        <Route path='/blog/sitemap-best-practices-to-improve-SEO/' element={<><NavBar /><SitemapSeo /><Footer /></>} />
        <Route path='/blog/google-analytics-4-setup-guide/' element={<><NavBar /><GoogleAnalystics /><Footer /></>} />
        <Route path='/blog/how-to-optimize-website-content-for-user-search-intent-search-query/' element={<><NavBar /><UserSearchIntentQuery /><Footer /></>} />
        <Route path='/blog/mistakes-to-avoid-with-running-twitter-ad-campaign/' element={<><NavBar /><RunningTwitter /><Footer /></>} />
        <Route path='/blog/tips-to-boost-the-seo-with-google-adwords/' element={<><NavBar /><BoostGoogleAdWords /><Footer /></>} />
        <Route path='/blog/7-ways-to-maintain-your-seo-ranking/' element={<><NavBar /><WaytoMaintain /><Footer /></>} />
        <Route path='/blog/reading-level-google-ranking-factor/' element={<><NavBar /><RankingFactor /><Footer /></>} />
        <Route path='/blog/content-checklist/' element={<><NavBar /><ContentChecklist /><Footer /></>} />
        <Route path='/blog/how-does-duplicate-content-hurt-your-website/' element={<><NavBar /><DuplicateContent /><Footer /></>} />
        <Route path='/blog/google-november-2021-algorithm-update/' element={<><NavBar /><GoogleAlgorithm /><Footer /></>} />
        <Route path='/blog/seo-checklist-for-holiday-season-ecommerce-sales-2021/' element={<><NavBar /><EcommerceSales /><Footer /></>} />
        <Route path='/blog/shopify-seo-guide-2022/' element={<><NavBar /><ShopifyStore /><Footer /></>} />
        <Route path='/blog/tips-to-improve-the-roi-of-your-paid-search-campaigns/' element={<><NavBar /><PaidSearchCampaigns /><Footer /></>} />
        <Route path='/blog/understanding-and-improving-your-pages-core-web-vitals/' element={<><NavBar /><CoreWebVitals /><Footer /></>} />
        <Route path='/blog/evergreen-content-traffic-booster/' element={<><NavBar /><BoostyourTraffic /><Footer /></>} /> */}
          <Route path='/blogs/:id/' element={<><NavBar /><DynamicBlogs /><Footer /></>} />
          <Route path='/blogs/topics/:id/' element={<><NavBar /><Topics /><Footer /></>} />
          {/* <Route path="/search/" element={<><NavBar /><Footer /></>} /> */}
          <Route path='/services/digital-marketing-services/mobile-seo/' element={<><NavBar /><MobileSeo /><Footer /></>} />
          <Route path='/services/digital-marketing-services/app-store-optimization/' element={<><NavBar /><AppStoreOptimization /><Footer /></>} />
          <Route path="/terms-conditions/" element={<><NavBar /><TermsConditions /><Footer /></>} />
          <Route path="/disclaimer/" element={<><NavBar /> <Disclaimer /> <Footer /></>} />
          <Route path="/site-map/" element={<><NavBar /> <SiteMap /> <Footer /></>} />
          <Route path="/thankyou/" element={<><NavBar /> <Thankyou /> <Footer /></>} />
          <Route path="/thank-you/" element={<><NavBar /> <ContactForm /> <Footer /></>} />
          {/* ------------------------------------------------------------------------------------- */}
          {/* <Route path="/addon/kb/" element={<><KnowledgeBase /></>} /> */}
          <Route path="/kb/" element={<><NavBar /><KnowledgeBase /><Footer /></>} />
          <Route path="/kb/:id/" element={<><NavBar /><DynamicKb /><Footer /></>} />
          <Route path="/knowledge-based/:idtwo/" element={<><NavBar /><DynamicKbTwo /><Footer /></>} />
          <Route path="/kb/search/" element={<><NavBar /><SearchKb /><Footer /></>} />
          {/* <Route path="/kb/account-sign-up/" element={<><NavBar /><SignupKb /><Footer /></>} />
        <Route path="/kb/account-activation/" element={<><NavBar /><AccountActiviationKb /><Footer /></>} />
        <Route path="/kb/account-login/" element={<><NavBar /><AccountLoginKb /><Footer /></>} />
        <Route path="/kb/free-trial/" element={<><NavBar /><FreeTrialKb /><Footer /></>} /> */}
          {/* --------------------------------------------------------------------------Updated codes----------------------------------------------------------------------- */}
          <Route path='/instant-audit-features' element={<><NavBar /><InstantAuditFeatures /><Footer /></>} />
          {/* <Route path='/action.php/' element={<><NavBar /><InstantAuditFeaturesRes /><InstantAuditFooter /></>} /> */}
          {/* <Route path='/invalid/' element={<><NavBar /><InstantAuditResInvalid /><InstantAuditFooter /></>} /> */}
          <Route path='/instant-audit-features/response-validation-form/' element={<><NavBar /><AccessValidation /><Footer /></>} />
          <Route path='/trial-report/' element={<><NavBar /><TrialReport /><Footer /></>} />
          <Route path="solutions/:id/" element={<><NavBar /><DynamicSolutions /><Footer /></>} />
          <Route path="/call-token-generator/" element={<><NavBar /> <TokenGenerator /> <Footer /></>} />
          {/* -----------------------------------------------------------------------End of Updated codes ------------------------------------------------------------------ */}
          {/* <Route path='/amphtml' element={<><AmpHtml /></>} /> */}
          {/* <Route path='/' element={<><CookiesPopup /></>} /> */}
          <Route path="*" element={<><NavBar /><Errorpage /><Footer /></>}/>
          <Route path="/sidebar" element={<><Sidebar /></>}/>
          <Route path="/solution-label" element={(sessionVal != '') ? <><Users /> <SolutionlabelForm /></> : <Loginform/> } />
          <Route path="/white-label" element={(sessionVal != '') ?<><Users /> <WhitelabelForm /> </> : <Loginform/>}/>
          <Route path="/user-list" element={(sessionVal != '') ?<><Users /> <Dashboard /> </> : <Loginform/>}/>
          <Route path="/contact-list" element={(sessionVal != '') ?<><Users /> <ContactList /> </> : <Loginform/>}/>
          <Route path="/error-user-list" element={(sessionVal != '') ?<><Users /> <ErrorDb /> </> : <Loginform/>}/>
          <Route path="contactview/:id/" element={(sessionVal != '') ?<><Users /><Contactview /></> : <Loginform/>} />
          <Route path="whitelabelview/:id/" element={(sessionVal != '') ?<><Users /><Whitelabelview /></> : <Loginform/>} />
          <Route path="solutionlabelview/:id/" element={(sessionVal != '') ?<><Users /><Solutionlabelview /></>: <Loginform/>} />
          {/* <Route exact path='/landing-page' element={<><NavBar /><LandingPage /> </>} /> */}
          {/* <Route exact path='/instand-mail-response' element={<><NavBar /><InstandMailResponse /> </>} />
          <Route exact path='/business-data' element={<><NavBar /><BusineesData /> </>} />
          <Route exact path='/otp-validation' element={<><NavBar /><OTPValidation /> </>} /> */}
        </Routes>
      </Suspense>
    </Router>
    </>
  );
}

export default App;
