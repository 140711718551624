import React, { useState, useRef, useEffect } from 'react';
import './Form.css';
import $ from 'jquery';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Form = () => {
    const [value, setValue] = useState()
    // const [select, setSelect] = useState(false);

    // const selectHandler = (event) => {
    //    setSelect(event.target.validation)
    // }

    const Ref = useRef(null);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const ServicesURL = `${process.env.REACT_APP_DOMAIN}/call-contact-form-api`;

    const navigate = useNavigate();

    const [checkBox, setCheckBox] = useState(false)
    const [captcha, setCaptcha] = useState(false)

    const callCheckFunction = () => {
        setCheckBox(!checkBox)
    }

    const checkCaptcha = () => {
        setCaptcha(true)
    }

    const [inputValues, setInputValue] = useState({
        name: "",
        email: "",
        phoneNo: "",
        message: ""
    });

    const { name, email, phoneNo, message } = inputValues

    const [validation, setValidation] = useState({
        name: "",
        email: "",
        phoneNo: "",
        message: "",
        checkbox: "",
        recaptcha: ""
    });

    const checkValidation = () => {
        let errors = { ...validation };

        //Name validation
        if ($('#name').val() == "") {
            errors.name = "This field is required.";
        } else if (!(/^[a-zA-Z]+$/g.test($('#name').val()))) {
            errors.name = "Please enter alphabet character only";
        } else {
            errors.name = "";
        }

        //Email validation
        if ($('#email').val() == "") {
            errors.email = "This field is required.";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test($('#email').val())) {
            errors.email = "Please enter valid Email address";
        } else {
            errors.email = "";
        }

        //Phone validation
        if ($('#phoneNo').val() === "") {
            errors.phoneNo = "This field is required.";
        }
        else if ($('#phoneNo').val().length < 10 || $('#phoneNo').val().length >= 15) {
            errors.phoneNo = "Please enter valid Phone number";
        }
        else {
            errors.phoneNo = "";
        }

        //Message validation
        if ($('#message').val() == "") {
            errors.message = "This field is required.";
        } else {
            errors.message = "";
        }

        //checkbox validation
        if (checkBox) {
            errors.checkbox = "";
        } else {
            errors.checkbox = "This field is required.";
        }

        //recaptcha validation
        if (captcha) {
            errors.recaptcha = "";
        } else {
            errors.recaptcha = "This field is required.";
        }

        return setValidation(errors);
    }

    // const onChange = (value) => {
    //     console.log("Captcha value:", value);
    //     setIsVerified({ isVerified: true })
    // }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputValue({ ...inputValues, [name]: value });
        // checkValidation();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        Ref.current.focus();
        checkValidation();
        // console.log("input-----", inputValues);
        if (name != '' && email != '' && phoneNo != '' && message != '' && checkBox && captcha) {
            setLoading(true)
            await axios.post(ServicesURL, {
                email: inputValues.email,
                url: "",
                name: inputValues.name,
                phone: value + " " + inputValues.phoneNo,
                // subject: "test",
                message: inputValues.message,
                competitorUrl: "",
            })
                .then((response) => {
                    console.log("Get.Data=====>", response)
                    //redirect logic
                    if (response.data.result == 200) {
                        navigate("/thankyou")
                    }
                })
                .catch(error => { console.log(error) })
            setLoading(false)
        } else {
            console.log("unfilled spaces, thows error")
        }
    }

    return (
        <div className="section_perf_form contact_us_page top_padding_50 service_footer_form_outer_wrap" style={{ backgroundSize: 'cover' }}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="common_code_inner">
                            <h2 className='form-heading'>The first step is easy – Just reach out!</h2>
                            <p className='form-para'>
                                Please fill out the below form with your details and we will get back to you shortly to discuss your business requirements.
                            </p>
                            <div className="contact_form">
                                <form id="contact_form_cus" className="needs-validation" onSubmit={handleSubmit} autoComplete="off">
                                    {/* <input type="hidden" name="_token" defaultValue="835yaUYRrrRltMgOoM2WgbZ0As9DUSL4LjPl0S8p" />                                 
                                    <input className="ohnohoney" autoComplete="off" type="text" id="first_name" name="first_name" placeholder="Your name here" /> */}
                                    <div className="form-inline row">
                                        <div className="form-group col-lg-4 col-md-12 col-sm-12 col-12 field-ht ">
                                            <input type="text" className="form-control form_controlpno" id="name" placeholder="Your Name *" name="name"
                                                onChange={(e) => handleChange(e)}
                                                value={inputValues.name} ref={Ref} />
                                            <span className='text-error-field'>{validation.name}</span>
                                            <div className="clearfix" />
                                        </div>

                                        <div className="form-group col-lg-4 col-md-12 col-sm-12 col-12 field-ht ">
                                            <input type="email" className="form-control form_controlpno" id="email" placeholder="Your Email *" name="email"
                                                onChange={(e) => handleChange(e)}
                                                value={inputValues.email} />
                                            <span className='text-error-field'>{validation.email}</span>
                                            <div className="clearfix" />
                                        </div>

                                        <div className='form-group col-lg-4 col-md-12 col-sm-12 col-12 field-ht flag-phone-number-field-cus'>
                                            <PhoneInput
                                                international
                                                defaultCountry="US"
                                                style={{ color: 'white', opacity: 1 }}
                                                value={value}
                                                onChange={setValue}
                                                className="form-controlpno flag-icon1"
                                            />
                                            <input type="number" className="phone-input-cus cl_contact cl_phone left-radius-0" id="phoneNo" placeholder="Phone Number *" name="phoneNo"
                                                value={inputValues.phoneNo} maxLength={15} onChange={(e) => handleChange(e)} />
                                        </div>
                                        <span className='text-errors-field flag-phone-input-error'>{validation.phoneNo}</span>
                                    </div>


                                    <div className="form_group bottom_margin_15">
                                        <textarea type="text" className="form-control textarea" id="message" placeholder="Your Message *" name="message"
                                            onChange={(e) => handleChange(e)}
                                            value={inputValues.message} />
                                        <div id="fm_privacy_policy6-error" />
                                        <span className='text-error-field message-box'>{validation.message}</span>
                                    </div>


                                    <div className="wdform-element-section common_center">
                                        <label htmlFor="fm_privacy_policy6" className="wdform-label privacy-unchecked" style={{ color: 'rgb(255, 255, 255)' }}>
                                            <input id="fm_privacy_policy6 " name="checkbox" className="wd-flex-row fm-gdpr-checkbox checkbox" type="checkbox"
                                                value={checkBox} onClick={callCheckFunction} />
                                            I consent to have this website store my submitted information so they can respond to my inquiry
                                            (<HashLink to="/terms-conditions/" target="_blank">Terms &amp; Conditions</HashLink> and
                                            <HashLink to="/privacy-policy/" target="_blank"> Privacy Policy</HashLink>) .</label>

                                        <div id="fm_privacy_policy6-error" />
                                        <span className='text-error-field checkbox2'>{validation.checkbox}</span>
                                    </div>
                                    <div className="contact_full common_center bottom_padding_15">
                                        {/* <center style={{ marginTop: '5px' }}>
                                            {/* <div id="recaptcha" class="g-recaptcha" data-sitekey="6LflX64cAAAAAAiR4Iy9PWSv4kc-j_LrJGEzuRK4"></div> 
                                            <div id="recaptcha" className="g-recaptcha" data-sitekey="6LeLSd4cAAAAAE-POFEiQ9wWyXvSTykPyL-IbDIp" />
                                            <span className="err-hide" id="err-recaptcha" />
                                            <label id="captacha-error" style={{ display: 'none' }}>This field is required.</label>
                                        </center> */}

                                        <div className='recaptcha-part mt-3'>
                                            <ReCAPTCHA
                                                sitekey="6LdkrQwkAAAAAOGkFoji7cLJxhE9NnOVSZEI_GEf"
                                                value={captcha}
                                                onChange={checkCaptcha}
                                                name="recaptcha"
                                                className='recaptcha-checkbox'
                                            />
                                        </div>
                                        <span className='text-error-field'>{validation.recaptcha}</span>

                                        <div className="common_center top_margin_20 bottom_margin_50">
                                            <button type="submit" className="btn btn-primary">
                                                {loading && <span>Submit</span>}
                                                {loading && (<i className="fa fa-spinner fa-spin" />)}
                                                {!loading && <span>Submit</span>}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/plugin/select2/css/select2.min.css" type="text/css" media="all" />
            <style dangerouslySetInnerHTML={{ __html: "\n\t\n\t.section_perf_form.contact_us_page { background-image: url(https://www.elitesiteoptimizer.com/str/uploads/Artboard_2_4c8d2a8f29.jpeg); }\n\t\n\n\n\n\t@media  only screen and (max-width:768px){\n.section_perf_form.contact_us_page { background-image: url(https://www.elitesiteoptimizer.com/str/uploads/medium_Artboard_2_4c8d2a8f29.jpeg); }\n}\n\n\n\n\n\t@media  only screen and (max-width:480px){\n.section_perf_form.contact_us_page { background-image: url(https://www.elitesiteoptimizer.com/str/uploads/small_Artboard_2_4c8d2a8f29.jpeg); }\n}\n\n\n" }} />
        </div>
    )
}

export default Form;