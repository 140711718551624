import React, { useState } from 'react';
import { filterDomain, emailValidator } from './regex';
import { useNavigate } from 'react-router-dom';
import './emailvalidation.css';
import axios from 'axios';

const EmailValidatorForm = () => {

    const [email, setEmail] = useState('')
    const userUrl = localStorage.getItem('userUrl')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    // const ApiUrl = `https://staging.elitesiteoptimizer.com/api/otpsend`
    const auth = {
        'username': 'devsite',
        'password': 'devsite'
    }
    
    const user_data = {
            email: `${email}`,
            site_url: `${userUrl}`
    }

    const navigate = useNavigate();

    const handleChange = (e) => {
        setEmail(e.target.value);
    }

    const submit = async (e) => {
        // navigate('/instant-audit-featuress/')
        e.preventDefault();
        setError("");
        const domainFilter = filterDomain(email);
        const validateEmail = emailValidator(email);

        if (domainFilter) {
            setError("Sorry only an authorized business email id is validated");
        } else if ((document.getElementById("email").value) === ''){
            setError("This field should not be empty!");
        } else if (validateEmail){
            localStorage.setItem('userEmail', email)
            setLoading(true)
            const response = await axios.post(`https://www.elitesiteoptimizer.com/call-otp-api`, user_data)
                .then((response) => {if(response.data.result == 200){
                    navigate('/instant-audit-features/response-validation-form')
                    localStorage.setItem('isValid', true)
                    return (
                    localStorage.setItem('otpGen', response.data.otp)
                )} else{
                    console.log('limit reached/ some error might happened')
                    setError(response.data.data)
                }})
                .catch(error => {console.log(error)})
            setLoading(false)
        } else {
            setError("Invalid email address");
        }
    }

    return (
        <div>
            <div className="et-main-area">
                <div style={{background: 'none'}} className="cta_bg_wrap_form">
                    <form>
                        <div className='validate-email-field-one'>
                            <br />
                            <h2 > Please Enter Your<br /> 
                             Business Mail ID here *</h2>
                            <br />
                            <h5 >Email Address</h5>
                            <input type='email' name="email" id="email" placeholder="Sample@businessmail.com" 
                            value={email} onChange={handleChange} /><br />
                            <span className='error-message'>{error}</span>
                            <br />
                            <h5 className='short-description'>We need your validation to ensure you are associated<br/> with this domain.</h5>
                            
                            
                            <button onClick={submit} className="btn btn-warning">
                            {loading && <span>Submit</span>}
                            {loading && (<i className="fa fa-spinner fa-spin" />)}
                            {!loading && <span>Submit</span>}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EmailValidatorForm;