import React , { useState, useEffect } from "react";
import axios from 'axios';
import {Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Sidebar from "./Sidebared.js";
import './Dashboard.css' 
import { MDBDataTable } from 'mdbreact';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import { useNavigate } from 'react-router-dom';
import { Link, useParams } from "react-router-dom";


const Solutionlabelview = () => {

  const [posts, setPosts] = useState([]);
  const [usersForRender, setUsersForRender] = useState([]);
    const [cardData, setCardData] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    let querystring = useParams();
    let contactid= querystring.id
    useEffect(() => {   
        callUserdataApi()    
    }, [])
    
    const callUserdataApi = async () => {
      setLoading(true)
      const datavalue= await axios.post(`${process.env.REACT_APP_DOMAIN}/call-view-response-solution`, {
        id: contactid})
      const dataval=datavalue.data.data[0];
      console.log(dataval);
      setCardData(dataval);
      setLoading(false)
  }
  return (

    <section className="menu-table tog_menu_table_exit">
      <div className="user">
        <div className="user5">
        <h3>Solution Label list</h3>
        <div className="link-set">
          <div className="link">
            <a href="">
              Home <span> &gt;</span>{" "}
            </a>
            <Link to='/solution-label' className="active">
              {" "}
              Solution Label list
            </Link>
          </div>
        
        </div>

        </div>
       
        <div className="tset">
       
<div class="box-col-12 ">
   <div class="card">
      <div class="card-body">

  <b>Solution Label Details:</b>

  <table border="none" width="100%">
    <tbody>
     
      <tr>
        <td>Company Name</td>
        <td>{cardData.companyName}</td>
      </tr>
    
      <tr>
        <td>Phone</td>
        <td>{cardData.phone}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>{cardData.email}</td>
      </tr>
      <tr>
        <td>About Company</td>
        <td>{cardData.about_Company}</td>
      </tr>
      <tr>
        <td>Company Website</td>
        <td>{cardData.websiteAddress}</td>
      </tr>
      <tr>
        <td>Fax</td>
        <td>{cardData.fax}</td>
      </tr>
      <tr>
        <td>Street</td>
        <td>{cardData.street}</td>
      </tr>
      <tr>
        <td>Country</td>
        <td>{cardData.country}</td>
      </tr>
      <tr>
        <td>Idea For Providing Website Optimization Solutions</td>
        <td>
        {cardData.opti_solution}
        </td>
      </tr>
      <tr>
        <td>Contact Person Name</td>
        <td>
        {cardData.contact_person_name}
        </td>
      </tr>
      <tr>
        <td>Contact Person Email</td>
        <td>
        {cardData.Contact_person_email}
        </td>
      </tr>
      <tr>
        <td>Contact Person phone</td>
        <td>
        {cardData.contact_person_phone}
        </td>
      </tr>
      <tr>
        <td>Product Solution General Information</td>
        <td>
        {cardData.product_solution_general_information}
        </td>
      </tr>
      <tr>
        <td>Product Solution Name</td>
        <td>
        {cardData.Product_solution_name}
        </td>
      </tr>
      <tr>
        <td>Product Web Page Address</td>
        <td>
        {cardData.Product_web_page_address}
        </td>
      </tr>
      <tr>
        <td>Product Solution Architecture</td>
        <td>
        {cardData.Product_solution_architecture}
        </td>
      </tr>
      <tr>
        <td>Customer Type</td>
        <td>
        {cardData.Customer_type}
        </td>
      </tr>
      <tr>
        <td>Additional Information</td>
        <td>
        {cardData.additional_info}
        </td>
      </tr>
      <tr>
        <td>Created Date</td>
        <td>{cardData.created_date}</td>
      </tr>
    
    </tbody>
  </table>


      
      </div>
   </div>
</div>
         

        </div>
       
      </div>
    </section>
   

   
  );
}


  export default  Solutionlabelview ;
