import React, { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {

    //Title Change
    useEffect(() => {
        document.title = 'Privacy Policy | SEO Automation Tool | Elite Site Optimizer';
    }, []);

    return (
        <div id="et-main-area">
        <Helmet>
        <meta
          name="description"
          content="Discover how Elite Site Optimizer handles your personal data. Read our Privacy Policy for information on data collection, use, and protection."
        />
        </Helmet>
            {/*Google Tag Manager (noscript) */}
            {/* Google Tag Manager (noscript) */}
            <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
                height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
            {/* End Google Tag Manager (noscript) */}
            <div className="terms_pages">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="common_text_inner top_padding_50 bottom_padding_50">
                                <h1 style={{}} className="common_center bottom_padding_30 static_terms" id="pv-1">Privacy Policy</h1>
                                <h2 style={{ textAlign: 'left' }} className="privacy_cls" id="pv-2">General Information</h2>
                                <div>
                                    <p className="privacy_cls">This policy (“Privacy Policy”) explains what information eGrove Systems Corporation doing business as Elite Site Optimizer and its subsidiaries (“EGROVE”) or our associates or our service providers (including Business Partners of EGROVE) collect from or about users of the eGrove websites, including any content EGROVE may provide through the websites (collectively, the “EGROVE WEBSITES”), the EGROVE services, including but not limited to Elite Site Optimizer, Elite mCommerce, Module Bazaar, or any other services that EGROVE may provide through the EGROVE Websites (collectively, the “Services Offerings”), our eCommerce services, our web services, our mobile applications, and other interactions (e.g., customer service inquiries, product inquiries, user conferences etc.), and states our practices regarding such information. If you are outside the European Economic Area (EEA), by accessing files, browsing, or otherwise using the EGROVE Websites, you have agreed to the use of your data as described in this Privacy Policy. If you are in the EEA, by accessing the EGROVE Websites, you have agreed to the use of my data as described in this Privacy Policy. It also describes the choices regarding use, access and collection of personal information as well as your rights to your personal data that we process. If you do not agree with the terms, please do not access or use the EGROVE Websites, Web services Service Offerings, mobile applications, or any other aspect of EGROVE’s business.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="privacy_cls" id="pv-3">Scope</h2>
                                <div>
                                    <p className="privacy_cls">This Privacy Policy applies to personal information and other information EGROVE or its associates or its service providers collect from or about the users of the EGROVE Websites, Service Offerings, Web Services, mobile applications and other interactions (e.g., customer service inquiries, product inquiries, user conferences etc.). Unless otherwise specified below, this Privacy Policy does not apply to any other products or services or to information collected in any other way (whether online or offline) or for any other purpose.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="privacy_cls" id="pv-4">Personal information of our customers’ clients and employees</h2>
                                <div>
                                    <p className="privacy_cls">a. EGROVE collects information under the direction of our customers, and has no direct relationship with the individuals whose personal data it processes on behalf of such customers. When we provide services to our customers, in some instances we process personal information about their clients or employees (“Client Information”) on their behalf. In these situations, it is our customers rather than we who decide the reasons for which the Client Information will be processed.</p>
                                    <p>b. If you are a client or employee of one of our customers and would no longer like to be contacted by one of our customers that use our service or for details of how the Client Information will be used and protected, and details of how to access or correct the information, please refer to the privacy statement of the EGROVE customer to which you submitted your personal information. We may transfer personal information to companies that help us provide our service. Transfers to subsequent third parties are covered by the service agreements with our customers.</p>
                                    <p>c. EGROVE acknowledges that you have the right to access your personal information. An individual who seeks access, or who seeks to correct, amend, or delete data should directly query EGROVE. If requested to remove data, we will respond within the applicable legal time-frame.</p>
                                    <p>d. We will retain personal data we process on behalf of our customers for as long as needed to provide services to our customers at their instruction. EGROVE may also retain this personal information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="privacy_cls" id="pv-5">Personal information</h2>
                                <div>
                                    <p className="privacy_cls">For purposes of this Privacy Policy, “personal information” means information that can be used to personally identify you, such as your name, address, telephone number, Instant message identifiers, website addresses, e-mail addresses, user IDs and passwords, online identifiers, device information, billing and transaction information, credit card or other financial information, contact preferences, and similar information.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="privacy_cls" id="pv-6">Personal information we collect</h2>
                                <div>
                                    <p className="privacy_cls">a. EGROVE or our associates or our service providers may collect, store, and process personal information and other information from or about you when you:</p><ol>
                                        <li>use EGROVE Websites or Service Offerings to order products or services;</li>
                                        <li>buy products;</li>
                                        <li>subscribe to our services;</li>
                                        <li>request information about our services/ products;</li>
                                        <li>subscribe to marketing or support materials;</li>
                                        <li>download EGROVE software, third party software, or open source software (“Software”) from EGROVE Websites;</li>
                                        <li>enter a contest;</li>
                                        <li>respond to promotional campaigns;</li>
                                        <li>register to a secure extranet site to obtain access to Software or Service Offering and any associated information, support, and training.</li>
                                        <li>elects to use our referral service for informing about our websites, products, web services, service offerings, mobile applications and EGROVE may ask for the referral’s name, email address.</li>
                                    </ol><p>b. We also may collect additional information that is related to you such as your computer’s or device’s operating system, browser, location, and your use of and activities on our websites.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="privacy_cls" id="pv-7">How we use the information we collect</h2>
                                <div>
                                    <div className="privacy_cls">
                                        <p className="privacy_cls">a. EGROVE or our associates or our service providers may use the personal information and other information we collect from you in various ways including in connection with:</p>
                                        <ol>
                                            <li>providing service, delivering products and providing support to you;</li>
                                            <li>billing you for Service Offerings;</li>
                                            <li>verifying your identity;</li>
                                            <li>carrying out our obligations;</li>
                                            <li>enforcing our rights arising from any contracts entered into between you and EGROVE;</li>
                                            <li>communicating information about EGROVE Websites, Service Offerings, Web services, our mobile applications, and other interactions (e.g., customer service inquiries, product inquiries, user conferences etc.);</li>
                                            <li>improving our EGROVE Websites, Web services, Services Offerings, our mobile applications, and other interactions (e.g., customer service inquiries, product inquiries, user conferences etc.);</li>
                                            <li>contacting you to complete surveys that we use for marketing or quality assurance purposes;</li>
                                            <li>sending you promotional information; sending emails to referral visitors inviting them to visit the website.</li>
                                            <li>complying with Service Offering obligations as well as in any other way we describe when you provide information;</li>
                                            <li>combating fraud, crime and as otherwise required or permitted by law; and</li>
                                            <li>for any other purpose with your consent.</li>
                                        </ol>
                                        <p>b. To assist us with the uses described in this Privacy Policy, information we, our associates or our service providers have collected from or about you through EGROVE Websites, EGROVE web services, EGROVE Service Offerings, mobile applications, and other interactions (e.g., customer service inquiries, product inquiries, user conferences etc.), including about your use thereof, may be combined with or enhanced by other information from or about you that we have obtained from other online or offline sources, including from our service providers or business partners.</p>
                                        <p>c. We may send you push notifications from time-to-time in order to update you about any events or promotions that we may be running. If you no longer wish to receive these types of communications, you may turn them off at the device level by opting out. To ensure you receive proper notifications, we will need to collect certain information about your device such as operating system and user identification information.</p>
                                        <p>d. EGROVE also have right to reject any service or product or offerings or services that does not complies with EGROVE’s domain or content policies. Elite Site Optimizer will not analyze websites that doesn’t complies with this domain or content policies. The domain or content policies includes,</p>
                                        <ol>
                                            <li>domain name should not be a porn site;</li>
                                            <li>domain name should not be a political site;</li>
                                            <li>the website or domain name should not contains offensive language</li>
                                        </ol>
                                    </div>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="privacy_cls" id="pv-8">Collection of information using cookies and other tools</h2>
                                <div>
                                    <div className="privacy_cls">
                                        <p>a. EGROVE may collect and/or log your Internet Protocol address, Internet domain name, the web browser and operating system used to access EGROVE Websites, Web Services, Service Offerings, mobile applications or other interactions (e.g., customer service inquiries, product inquiries, user conferences etc.), the pages or files visited, the time spent in each page or file, and the time and date of each visit or click-stream data. EGROVE may collect this information automatically as you browse via the use of log files and web beacons to analyze trends in the aggregate and administer the EGROVE Websites, Web Services and Service Offerings. EGROVE and its partners use cookies or similar technologies to analyze trends, administer the website, track users’ movements around the website, and to gather demographic information about our user base as a whole. You can control the use of cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functions on our website or service.</p>
                                        <p>b. We may also use cookies and other electronic tools placed by a third-party service provider to measure the effectiveness of our advertising, campaigns and other information and help us understand what product information is of most interest to our customers and what kinds of advertising offers our customers like to see. Although the service provider logs the information coming from EGROVE Websites or Service Offerings on our behalf, EGROVE controls how that data may and may not be used. You can control the use of cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functions on our website or service.</p>
                                        <p>c. If you wish to opt out of interest-based advertising, please contact us at the email address provided in this Privacy Policy or follow the opt-out instructions in the email.</p>
                                        <p>d. We use mobile analytics software to allow us to better understand the functionality of our mobile applications on your phone. This software may record information such as how often you use the application, the events that occur within the application, aggregated usage, performance data, and where the application was downloaded from. We do not link the information we store within the analytics software to any personal information you submit within the mobile application.</p>
                                    </div>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="privacy_cls" id="pv-9">Sharing your information</h2>
                                <div>
                                    <div className="privacy_cls">
                                        <p>a. EGROVE may share non-personal information (e.g., anonymous, aggregated data) without restriction. EGROVE may share your personal information as described in this Privacy Policy and with its service providers, associates, vendors, suppliers and other business partners to the extent necessary to deliver the EGROVE Websites, Web Services or Products or Service Offerings you requested, respond to your requests for information on Software or Service Offerings, or otherwise support your business needs. If you opt out of sharing your information with these third-party service providers for these purposes, we may be unable to provide the EGROVE Websites, Web Services, Products, Service Offerings, mobile applications, information or other support you requested.</p>
                                        <p>b. Third-party service providers and suppliers receiving personal information are authorized to use your personal information only for the purpose it was originally intended or as required or permitted by law. However, unless otherwise dictated by law or agreement, EGROVE is not responsible for the privacy practices, improper use of privacy information or the content of third-party websites. You will need to check the terms of use and privacy policies of such third-party sites to understand their policies.</p>
                                    </div>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="privacy_cls" id="pv-10">Sharing personal information across country borders</h2>
                                <div>
                                    <div className="privacy_cls">
                                        <p>a. We may also disclose your personal information and other information, including across country borders:</p>
                                        <ol>
                                            <li>to our subsidiaries, affiliates, and worldwide offices;</li>
                                            <li>to comply with the law or in response to a subpoena, court order, law enforcement or government request, or other legal process;</li>
                                            <li>to produce relevant documents or information in connection with litigation, arbitration, mediation, adjudication, government or internal investigations, or other legal or administrative proceedings;</li>
                                            <li>if EGROVE determines in its good faith judgment that such disclosure is necessary to provide its services or to protect the rights, interests, safety, or property of its business, employees, suppliers, customers, or others;</li>
                                            <li>in connection with any proposed or actual sale or other transfer of some or all assets of EGROVE, and/or any proposed or actual sale or merger of EGROVE or any division of EGROVE;</li>
                                            <li>to enforce the terms of any agreement with EGROVE;</li>
                                            <li>to combat fraud or other criminal activity;</li>
                                            <li>for any other purpose disclosed by us when you provide the information;</li>
                                            <li>as otherwise required or permitted by law; and/or</li>
                                            <li>with your consent.</li>
                                        </ol>
                                        <p>b. In certain cases EGROVE offers European Union Model Clauses, also known as Standard Contractual Clauses, to meet the adequacy and security requirements for our customers that operate in the European Union, and other international transfers of customer data.</p>
                                        <p>c. Unless permitted by relevant laws, EGROVE will not share, sell, trade, or lease your personal information to or with others except as provided for in this Privacy Policy.</p>
                                    </div>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="privacy_cls" id="pv-11">Marketing choices, right to access your personal information, and our retention</h2>
                                <div>
                                    <div className="privacy_cls">
                                        <p>a. Your Marketing Choices. Your decision to provide the personal information that we may request from time to time is voluntary. However, if you do not provide the personal information requested, you may not be able to proceed with the activity or receive the benefit for which the personal information is being requested. You can always unsubscribe or choose not to receive promotional materials from EGROVE by following the specific instructions in the email or other communication you receive or by notifying us via the appropriate method(s) described below. It may take a reasonable period of time to process your request, but no longer than 10 business days for email promotions. In countries where EGROVE is required to do so by law, EGROVE will offer you the opportunity to provide opt-in consent before promotional materials are sent, or promotional calls are made, to you. This will not apply to service communications that are considered part of certain Service Offerings, which you may receive periodically unless you cancel the applicable Service Offerings.</p>
                                        <p>b. Your Access to Your Personal Information. EGROVE strives to keep your personal information accurate. We have implemented technology, management processes, and policies designed to maintain data integrity. We will provide you with reasonable access to your personal information in accordance with relevant laws, including by making reasonable efforts to provide you with online access and the opportunity to update your information. To protect your privacy and security, we will take steps to verify your identity before granting access or making changes to your personal information. Upon request, EGROVE will provide you with information about whether we hold any of your personal information. To request access, correction, or deletion or transfer of your personal information, follow the applicable instructions below. While we will make reasonable efforts to accommodate your request, we also reserve the right to reject such requests or to impose certain restrictions and requirements on such requests, if required or permitted by applicable law. We will respond to your request within a reasonable time frame.</p>
                                        <p>c. Our Retention of Your Information. We will retain your personal information for as long as your account is active or as needed to provide you EGROVE Websites, Web Services, Products, Service Offerings and mobile applications. If you wish to cancel your account, or request that we no longer use your personal information to provide you Software or Services, contact us via the applicable methods provided below. Even after we cancel your account or cease using your personal information to provide you Software or Services, we may still retain, use, and disclose your information as necessary to comply with our legal, regulatory, ethical, or document retention obligations, and any request to delete your personal information is subject to these obligations and restrictions.</p>
                                    </div>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="privacy_cls" id="pv-12">Security of your personal information</h2>
                                <div>
                                    <div className="privacy_cls">
                                        <p>EGROVE has implemented administrative, technical, and physical measures designed to protect your personal information from accidental loss and from unauthorized access, disclosure, use, alteration, or destruction. These measures include computer safeguards and secured files and buildings, Internet firewalls, intrusion detection, anti-virus protection, network monitoring, and, where appropriate and required by applicable law, Transport Layer Security (“TLS”) or similarly encrypted browsers. However, no electronic data transmission or storage of information can be guaranteed to be 100% private and secure, and you understand that EGROVE does not ensure or warrant the privacy or security of any information we collect from or about you, and that you use the EGROVE Websites and provide us with information at your own risk. If a password is used to help protect your accounts and personal information, it is your responsibility to keep your password confidential. Please also be careful to avoid “phishing” scams, where someone may send you an email that looks like it is from EGROVE asking for your personal information. EGROVE will never request your personal information through e-mail. If you have any questions about the security of your personal information, you can contact us at set forth below.</p>
                                    </div>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="privacy_cls" id="pv-13">Egrove contact information for marketing choices, requests for access, correction deletion or transfer, and questions about and enforcement of this privacy policy</h2>
                                <div>
                                    <div className="privacy_cls">
                                        <p>a. Unsubscribe from EGROVE emails using the link provided in our emails. Alternatively, you can contact <a style={{ fontSize: '18px' }} href="mailto:sales@elitesiteoptimizer.com?Subject=UNSUBSCRIBE" target="_top">sales@elitesiteoptimizer.com</a> with subject line <strong>‘Unsubscribe’</strong>.</p>
                                        <p>b. To request access, correction, deletion or transfer of your personal information or withdrawal of consent to the processing described in this Privacy Policy, please email us at: <a style={{ fontSize: '18px' }} href="mailto:sales@elitesiteoptimizer.com?Subject=PRIVACY%20POLICY%20-%20PERSONAL%20INFORMATION%20REGARDING" target="_top">sales@elitesiteoptimizer.com</a> with subject line <strong>‘privacy policy – personal information regarding’</strong>.</p>
                                        <p>c. If you have questions regarding our compliance with this Privacy Policy, please contact us by mail at<br /><strong>eGrove Systems Corporation</strong><br />2G Auer Ct.<br />East Brunswick<br />NJ 08816<br />Attn: Legal;</p>
                                        <p>or</p>
                                        <p>emailing us at <a style={{ fontSize: '18px' }} href="mailto:sales@elitesiteoptimizer.com?Subject=PRIVACY%20POLICY%20-%20LEGAL%20COMPLIANCE%20REGARDING" target="_top">sales@elitesiteoptimizer.com</a> with subject line <strong>‘Privacy Policy – legal compliance regarding’</strong>.</p>
                                        <p>d. If you are a resident of the EEA and have an unresolved privacy or personal information collection, use, or disclosure concern that we have not addressed satisfactorily, please contact the EU Data Protection Authorities. For more information on how to contact the EU Data Protection Authorities, <a style={{ fontSize: '18px' }} href="https://ec.europa.eu/info/law/law-topic/data-protection_en" target="_blank" rel="noopener">click here</a></p>
                                        <p>e. If you are a resident of Switzerland and have this concern, please contact the Swiss Federal Data Protection and Information Commissioner. For more information on how to contact the Swiss Federal Data Protection and Information Commissioner, <a style={{ fontSize: '18px' }} href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html" target="_blank" rel="noopener">click here</a></p>
                                    </div>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="privacy_cls" id="pv-14">Changes to this privacy policy</h2>
                                <div>
                                    <p>EGROVE may update this Privacy Policy periodically, and will note the date of its most recent revision above. We encourage you to review this Privacy Policy frequently to be informed of how EGROVE is collecting, using, retaining, protecting, disclosing, and transferring your information.</p>
                                    <p><strong>This Policy was last updated on August 28, 2018.</strong> <HashLink to="/privacy-policy-old/" target="_blank" rel="noopener" elementId='root'>Click here</HashLink> to view the previous version of the privacy policy</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;