import React, { useState, useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { HashLink } from 'react-router-hash-link';
import { Link, useParams } from "react-router-dom";
import './loaderstyles.css';
const LatestTopics = () => {
    const [token, setToken] = useState()
    const [countOne, setCountOne] = useState(0)
    const [countTwo, setCountTwo] = useState(0)
    const [latestTopics, setLattestTopics] = useState([])
    const [isLoading, setLoading] = useState(false)
    

    let { id } = useParams();


    const dataParams = {
        identifier: 'admin',
        password: 'admin@123!'
    }
const loadMorebutton= async () =>{
    getLatestTopicData();
}
    const getLatestTopicData = async (e) => {
        setLoading(true)
        window.scrollBy(0, 0);
        setLattestTopics([])
        var dataCoutone=countTwo+3;
        setCountTwo(dataCoutone)
          const dataParams = {
            token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
            id: id,
            countone:0,
            countTwo:dataCoutone,
          }
          axios.post(`${process.env.REACT_APP_DOMAIN}/call-lattest-pick-blogs`, dataParams).then((res) => { 
            console.log(res.data[0].featured_image.image_file.url);
            setLattestTopics(res.data) })
         
          setLoading(false)
      }

    useEffect(() => {
        getLatestTopicData();
    
    }, [])

    const renderLatestTopic = (
        <div className="row bgs-posts">
            {latestTopics.length>0?latestTopics.map((items, index) => {
                let rawdate = new Date(items?.created_at)   
                
                let date = rawdate.getDate()
                let month = rawdate.toLocaleString('default', { month: 'long' })
                let year = rawdate.getFullYear()
                return (
                    <div className="col-lg-4 col-md-6 col-sm-12 layout-simple">
                        <article className="bgs-post bgs-post-a bgs-box" id={items.id}>
                            <div className="bnt-image">
                                <HashLink elementId='root' to={`/blogs/${items.url_slug}`} title={items.title}>
                                    <img alt={items?.featured_image?.alt_text? items?.featured_image?.alt_text:"Blog Images"} src={`${process.env.REACT_APP_BLOGS_API}/${items?.featured_image?.image_file?.url}`}  />
                                </HashLink>
                            </div>
                            <div className="box-inner-p">
                                <div className="box-inner-ellipsis">
                                    <div>
                                        <h2 className="bnt-title h3"><Link to={`/blogs/${items?.url_slug}`} state={{ from: `${items.url_slug}` }}>{items?.title}</Link></h2>
                                    </div>
                                </div>
                                <div className="bnt-meta">
                                    <div className="meta-item meta-date"><span className="updated">{`${month} ${date}, ${year}`}</span>
                                    {/* <span className="meta-item meta-views">{items.views === null ? 0 : items.views} Views</span>
                                    | */}
                                    </div>
                                </div>
                                <div className="bnt-category" style={{marginTop:'7px'}}>
                                    {items?.categories.map((subItem, subIndex) => {
                                        return <span><Link to={`/blogs/topics/${subItem?.url_slug}`} state={{ from: `${subItem?.url_slug}` }} style={{ border: 'none' }} className="blog_categ" rel="category tag" >{subItem?.name}</Link>&nbsp;&nbsp;</span>
                                    })}
                                </div>
                                <div className="bnt-content-cus">
                                <p className='short-description' style={{ fontSize: '14px' }}>{parse(items.page_content)}</p>
                                </div>
                            </div>
                        </article>
                        
                    </div>
                   
                )
            }):  <i className="fa fa-spinner fa-spin" />}
             {latestTopics.length>0? <nav class="bgs-pagination bgs-load-more col-12 common_center top_padding_30 bottom_padding_30"><a class="loadmore-btn" onClick={loadMorebutton}><span>Load More
                            </span></a></nav> :""}
        </div>
    )

    return (
        <div>
            {isLoading ?   <i className="fa fa-spinner fa-spin" />
 : renderLatestTopic}
        </div>
    )
}

export default LatestTopics;