import React, { useState, useEffect, Suspense } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

import parse from 'html-react-parser';
import headerAstro from '../../images/astro/bot-with-bow-target.png';
import { HashLink } from 'react-router-hash-link';
import { urlValidator } from './regex';
import { useNavigate } from 'react-router-dom';
import Bg1 from '../../../src/images/eso/home_image/bg_1.png';
import Rank_Analysis from '../../images/str/uploads/Rank_Analysis.png';
import On_page_data from '../../images/str/uploads/On_page_data.png';
import Performance from '../../images/str/uploads/Performance.png';
import Link_Checker from '../../images/str/uploads/Link_Checker.png';
import schema_validator from '../../images/str/uploads/schema_validator.png';
import Site_map from '../../images/str/uploads/Site_map.png';

import ESO_offer from '../../images/eso/home_image/ESO_offer.png';
import digital_marketingicon from '../../images/str/uploads/digital_marketingicon.png';
import optimization from '../../images/str/uploads/optimization.png';
import banner1 from '../../images/str/uploads/banner1.png';
import bg_2 from '../../images/eso/home_image/bg_2.png';
import solution_img3 from '../../images/eso/home_image/solution_img3.png';
import bg_4 from '../../images/eso/home_image/bg_4.png';
import bg_3 from '../../images/eso/home_image/bg_3.png';
import CookiesPopup from '../../components/CookiesPopup/CookiesPopup';
import './home.css';
import { Helmet } from 'react-helmet';
import { RotatingLines } from 'react-loader-spinner';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {Modal, Button} from 'react-bootstrap';


// import Slider from '../../components/Slider/Slider';
const Slider = React.lazy(() => import('../../components/Slider/Slider'));
const HomeBlogs = React.lazy(() => import('../../components/Blogs/HomeBlogs'))


const Home = () => {

  
  const [authToken, setAuthToken] = useState()


  // const callAPIForToken = async () => {

  //   const dataParams = {
  //     identifier: 'admin',
  //     password: 'admin@123!'
  // }
  //   const apiUrl = `https://strapi.egrovetech.com/auth/local`;
  //   await axios.post(apiUrl, dataParams).then((res) => setAuthToken(res.data.jwt))
  // }

  // console.log(authToken)

  // const callApiForDynamicHomePage = async () => {
  //   await axios.get(`${process.env.REACT_APP_BLOGS_API}/blogs?publish_status=Publish&_sort=date_time_publish:DESC&_limit=3`).then((res) => { setRecentBlogData(res.data) })
  // }


  useEffect(() => {
    handleShow()
    document.getElementById("navbarSupportedContent").classList.remove("show");
    document.title = 'Website Optimization Services | Website Optimization Tools | ESO';
    callAPIforClientimages()
  }, []);

  const getCookieAgree = localStorage.getItem('cookieAgree')

  const [url, setUrl] = useState('')
  const [error, setError] = useState('')
  const [clientimage, setClientimage] = useState([])
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    
  const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
  };



  const handleChange = (u) => {
    setUrl(u.target.value);
  }

  const submit = (u) => {
    u.preventDefault();
    setError("");

    const validateUrl = urlValidator(url);
    const userUrl = localStorage.setItem('userUrl', url)

    if ((document.getElementById("url").value) === '') {
      setError("This field should not be empty!");
    } else if (!validateUrl) {
      setError("Please enter a URL starting with http:// or https://");
    } else if (validateUrl) {
      localStorage.setItem('userUrl', url)
      return navigate('/instant-audit-features/')
    }
  }
  const callAPIforClientimages = async () => {
      console.log("kannanerr",`${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`)
    const dataParams = {
        token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
    }

   const response_data= await axios.get(`${process.env.REACT_APP_BLOGS_API}/our-clients`, dataParams)
   console.log("responsedata",response_data)
     setClientimage(response_data.data) 
}
  return (
    <div>
      <div>
      {/* <Modal show={show} onHide={handleClose} centered>
        <Modal.Header >
          <Modal.Title><p><center><span style={{color:"#FF0000"}}>Important Security Notice:</span></center></p>
          <p> <span >Beware of Unauthorized Websites Impersonating Us!</span></p> </Modal.Title>
        </Modal.Header>
        <Modal.Body>We have been made aware of fraudulent entities attempting to replicate our online presence. Please report any suspicious activities to your local law enforcement for immediate action.</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal> */}
        {/* --------------------------------------------------------------Updated codes---------------------------------------------------------------------------------------- */}
        <div id="et-main-area">
          <Helmet>
            <title>Website Optimization Services | Website Optimization Tools | ESO</title>
            <meta name="description"
              content="Elite Site Optimizer – A comprehensive website optimization tool that offers SEO, instant audit reports, accessibility, usability, and code audit services."></meta>
          </Helmet>
          {/*Google Tag Manager (noscript) */}
          {/* Google Tag Manager (noscript) */}
          {/* <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
                height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript> */}
          {/* End Google Tag Manager (noscript) */}
          {/* End Header */}
          {/* Banner */}
          <div className="cta_bg_wrap_home_banner">
            {/* <div className="cta_bg_img_left_outer_wrap">
              <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_4.png" alt="CTA_bg" />
            </div> */}

            <div className='container'>
              <div className='row'>
                <div className='col-xl-8 col-lg-8 col-md-12'>
                  <h2 className='home_banner_topic'>&nbsp;</h2>
                  <h5 className='sub_content'>&nbsp;</h5>
                  <form className="homepage-audit-url" action="action_page.php">
                    <input id='url' type="text" placeholder="Enter your URL here with http:// or https://" name="search" aria-label="Website URL" value={url} onChange={handleChange} />
                    <button style={{ fontWeight: 'bold', backgroundColor: 'orange', borderTopRightRadius: '25px', borderBottomRightRadius: '25px', borderWidth: '0px' }} onClick={submit} className="run-free-trial" >Run Free Trial Audit</button>

                    <div className='error-message' style={{ color: "red", alignContent: 'center', marginLeft: '140px' }}>{error}</div>
                  </form>

                  <div className='Bottom'>
                  </div>
                </div>
                <div className='col-xl-4 col-lg-4 col-md-14'>
                  <img id='headerAstro' src={headerAstro} alt ='bot-with-target' height='100%' width='350px' />
                </div>
              </div>
            </div>
          </div>
          {/* ---------------------------------------------------------------End of updated codes--------------------------------------------------------------------------------------- */}
          {/* End Banner */}   
            {/* <div className="container client_title_outer_wrap">
                <div className="common_title_outer_wrap">
                    <h2>Our Clients</h2>
                </div>
            <Carousel draggable={true} swipeable={true} infinite={false} responsive={responsive} className="card_row" autoPlay={ true }
  autoPlaySpeed={1250} infinite={true}>
          
                {clientimage.length > 0 && clientimage.map((item, index) => (
                 <div>
               
                <img src={`${process.env.REACT_APP_BLOGS_API}/${item?.Image?.url}`}
                 alt="Elitesiteoptimizer - custom solution" title="Elitesiteoptimizer - custom solution" style={{ width: 200, height: 100 }}  />
                  </div>
               ))}
            </Carousel>
            </div> */}
          {/* What we do */}

          <div className="common_overflow_wrap">
            <div className="container">
              <div className="row what_we_do_row_wrap">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="what_we_do_content_outer_wrap">
                    <div className="common_title_outer_wrap">

                      <h2>What we do</h2>
                      <h3>The Best Website</h3>
                      <h1>Audit Platform</h1>
                    </div>
                    <p>Elite Site Optimizer is a customizable platform that’s designed for search engine experts, marketing professionals, and business owners who want their website to rank at the top in search engine results and fulfill their business goals.</p>
                    <h5>What Elite Site Optimizer can do for you:</h5>
                    <ul className="arrow_listing_outer_wrap">
                      <li>Audit your website’s content and code</li>
                      <li>Find out if your site is accessible</li>
                      <li>Make your site more discoverable on search engines</li>
                      <li>Help you build your own SEO marketing strategy</li>
                      <li>Provide reports about every last detail of your site.</li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="what_we_do_img_outer_wrap">
                    <img src={Bg1} alt="what_we_do_bg_wrap" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End of What we do */}
          {/* Tabs */}
          <div className="tabs_bg_outer_wrap common_overflow_wrap" >
            <div className="container" style={{ align: "center" }}>
              <div className="tabbable-panel custom_tabs_outer_wrap">
                <div className="tabbable-line">
                  <ul className="nav nav-tabs">
                    <li>
                      <a onClick={() => {
                        document.getElementById("svg1").setAttribute("fill", "#FFAE1F")
                        document.getElementById("svg2").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg3").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg4").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg5").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg6").setAttribute("fill", "#5c5c5c")
                      }} href="#name_tabs0" className="nav-link active" data-toggle="tab">
                        {/* <i className='eso_rank' aria-label="eso_rank" role="img"></i> */}
                        <svg id='svg1' xmlns="http://www.w3.org/2000/svg" width="43.106" height="39" viewBox="0 0 43.106 39" fill="#FFAE1F">
                          <g id="Group_6610" data-name="Group 6610" transform="translate(-292.601 -436.775)">
                            <g id="Group_6608" data-name="Group 6608" transform="translate(296.706 444.987)">
                              <path id="Path_4591" data-name="Path 4591" d="M297.226,450.118l3.309,13.235h23.029l3.311-13.24L318.736,455a1.027,1.027,0,0,1-1.447-.421l-5.24-10.485-5.269,10.487a1.028,1.028,0,0,1-1.447.419l-8.106-4.879Zm28.165,14.4v6.024a1.025,1.025,0,0,1-1.026,1.026H299.733a1.025,1.025,0,0,1-1.026-1.026V464.5l-4.072-16.292a1.025,1.025,0,0,1,1.523-1.129l9.284,5.587,5.692-11.327a1.026,1.026,0,0,1,1.835,0l5.661,11.324,9.313-5.587a1.027,1.027,0,0,1,1.525,1.129l-4.077,16.3Zm-24.632.893v4.105h22.579v-4.105Z" transform="translate(-294.601 -440.776)" />
                            </g>
                            <g id="Group_6609" data-name="Group 6609" transform="translate(292.601 436.775)">
                              <path id="Path_4592" data-name="Path 4592" d="M314.154,447.038a5.132,5.132,0,1,1,5.132-5.132,5.131,5.131,0,0,1-5.132,5.132Zm0-2.053a3.079,3.079,0,1,0-3.079-3.079,3.079,3.079,0,0,0,3.079,3.079ZM296.706,453.2a4.105,4.105,0,1,1,4.105-4.105,4.1,4.1,0,0,1-4.105,4.105Zm0-2.053a2.053,2.053,0,1,0-2.053-2.053,2.052,2.052,0,0,0,2.053,2.053Zm34.9,2.053a4.105,4.105,0,1,1,4.105-4.105A4.1,4.1,0,0,1,331.6,453.2Zm0-2.053a2.053,2.053,0,1,0-2.053-2.053,2.052,2.052,0,0,0,2.053,2.053Z" transform="translate(-292.601 -436.775)" />
                            </g>
                          </g>
                        </svg>
                        <h5> Rank Analysis</h5>
                      </a>
                    </li>
                    <li>
                      <a onClick={() => {
                        document.getElementById("svg2").setAttribute("fill", "#FFAE1F")
                        document.getElementById("svg1").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg3").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg4").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg5").setAttribute("fill", "#5c5c5c")
                      }} href="#name_tabs1" className="nav-link" data-toggle="tab">
                        {/* <i className='eso_internal_icon' aria-label="eso_internal_icon" role="img"></i> */}
                        <svg id='svg2' xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="#5c5c5c">
                          <g id="Group_6594" data-name="Group 6594" transform="translate(-170 -511)">
                            <g id="Group_6592" data-name="Group 6592" transform="translate(171.857 516.571)">
                              <path id="Path_4581" data-name="Path 4581" d="M174.986,523.557a.93.93,0,0,1,1.313,0l3.714,3.714a.929.929,0,0,1-1.313,1.315l-2.128-2.13v8.9A4.642,4.642,0,0,0,181.215,540H197v1.857H181.215a6.5,6.5,0,0,1-6.5-6.5v-8.9l-2.13,2.13a.929.929,0,0,1-1.313-1.315l3.714-3.714ZM202.3,532.3a.93.93,0,0,1-1.313,0l-3.714-3.714a.929.929,0,0,1,1.313-1.315l2.13,2.13v-8.9a4.642,4.642,0,0,0-4.643-4.643H180.286V514h15.786a6.5,6.5,0,0,1,6.5,6.5v8.9l2.128-2.13a.929.929,0,1,1,1.313,1.315L202.3,532.3Z" transform="translate(-171 -514)" />
                            </g>
                            <g id="Group_6593" data-name="Group 6593" transform="translate(170 511)">
                              <path id="Path_4582" data-name="Path 4582" d="M194.414,524.271a.928.928,0,0,1,1.313,1.313l-4.914,4.914,4.914,4.914a.928.928,0,0,1-1.313,1.313l-4.914-4.914-4.914,4.914a.929.929,0,1,1-1.315-1.313l4.914-4.914-4.914-4.914a.929.929,0,0,1,1.315-1.313l4.914,4.914,4.914-4.914ZM172.786,511h7.429A2.787,2.787,0,0,1,183,513.786v7.429A2.787,2.787,0,0,1,180.214,524h-7.429A2.787,2.787,0,0,1,170,521.214v-7.429A2.787,2.787,0,0,1,172.786,511Zm0,1.857a.929.929,0,0,0-.929.929v7.429a.929.929,0,0,0,.929.929h7.429a.929.929,0,0,0,.929-.929v-7.429a.929.929,0,0,0-.929-.929ZM206.214,550h-7.429A2.787,2.787,0,0,1,196,547.214v-7.429A2.787,2.787,0,0,1,198.786,537h7.429A2.787,2.787,0,0,1,209,539.786v7.429A2.787,2.787,0,0,1,206.214,550Zm0-1.857a.929.929,0,0,0,.929-.929v-7.429a.929.929,0,0,0-.929-.929h-7.429a.929.929,0,0,0-.929.929v7.429a.929.929,0,0,0,.929.929Z" transform="translate(-170 -511)" fill-rule="evenodd" />
                            </g>
                          </g>
                        </svg>
                        <h5> Internal<br /> Links</h5>
                      </a>
                    </li>
                    <li>
                      <a onClick={() => {
                        document.getElementById("svg3").setAttribute("fill", "#FFAE1F")
                        document.getElementById("svg1").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg2").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg4").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg5").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg6").setAttribute("fill", "#5c5c5c")
                      }} href="#name_tabs2" className="nav-link" data-toggle="tab">
                        {/* <i class="eso_analysis" aria-label="eso_analysis" role="img" /> */}
                        <svg id='svg3' xmlns="http://www.w3.org/2000/svg" width="35.286" height="39" viewBox="0 0 35.286 39" fill="#5c5c5c">
                          <g id="Group_6598" data-name="Group 6598" transform="translate(-271 -411)">
                            <g id="Group_6596" data-name="Group 6596" transform="translate(271 411)">
                              <path id="Path_4583" data-name="Path 4583" d="M296.907,414.714H303.5a2.787,2.787,0,0,1,2.786,2.786v26a6.5,6.5,0,0,1-6.5,6.5H277.5a6.5,6.5,0,0,1-6.5-6.5v-26a2.787,2.787,0,0,1,2.786-2.786h6.593a4.645,4.645,0,0,1,4.55-3.714h7.429a4.646,4.646,0,0,1,4.55,3.714Zm-16.529,1.857h-6.593a.929.929,0,0,0-.929.929v26a4.642,4.642,0,0,0,4.643,4.643h22.286a4.642,4.642,0,0,0,4.643-4.643v-26a.929.929,0,0,0-.929-.929h-6.593a4.646,4.646,0,0,1-4.55,3.714h-7.429a4.645,4.645,0,0,1-4.55-3.714Zm4.55-3.714a2.786,2.786,0,1,0,0,5.571h7.429a2.786,2.786,0,0,0,0-5.571Z" transform="translate(-271 -411)" />
                            </g>
                            <g id="Group_6597" data-name="Group 6597" transform="translate(276.571 425.857)">
                              <path id="Path_4584" data-name="Path 4584" d="M274.929,420.857a.929.929,0,0,1,0-1.857h11.143a.929.929,0,0,1,0,1.857Zm0,7.429a.929.929,0,1,1,0-1.857h22.286a.929.929,0,1,1,0,1.857Zm0,7.429a.929.929,0,1,1,0-1.857h22.286a.929.929,0,1,1,0,1.857Z" transform="translate(-274 -419)" />
                            </g>
                          </g>
                        </svg>
                        <h5> On Page Analysis</h5>
                      </a>
                    </li>
                    <li>
                      <a onClick={() => {
                        document.getElementById("svg4").setAttribute("fill", "#FFAE1F")
                        document.getElementById("svg1").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg2").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg3").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg5").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg6").setAttribute("fill", "#5c5c5c")
                      }} href="#name_tabs3" className="nav-link" data-toggle="tab">
                        {/* <i class="eso_performance_icon" aria-label="eso_performance_icon" role="img"></i> */}
                        <svg id='svg4' xmlns="http://www.w3.org/2000/svg" width="40.95" height="39" viewBox="0 0 40.95 39" fill="#5c5c5c">
                          <g id="Group_6595" data-name="Group 6595" transform="translate(-170 -362)">
                            <g id="Group_6587" data-name="Group 6587" transform="translate(171.95 367.85)">
                              <path id="Path_4576" data-name="Path 4576" d="M172.95,366.95v16.575a4.874,4.874,0,0,0,4.875,4.875h23.4a4.874,4.874,0,0,0,4.875-4.875V366.95Zm-.975-1.95h35.1a.975.975,0,0,1,.975.975v17.55a6.825,6.825,0,0,1-6.825,6.825h-23.4A6.825,6.825,0,0,1,171,383.525v-17.55a.975.975,0,0,1,.975-.975Z" transform="translate(-171 -365)" />
                            </g>
                            <g id="Group_6588" data-name="Group 6588" transform="translate(185.599 391.25)">
                              <path id="Path_4577" data-name="Path 4577" d="M182,377.54l-3.9,7.8a.975.975,0,0,0,1.743.872l3.9-7.8A.975.975,0,1,0,182,377.54Z" transform="translate(-178 -377)" />
                            </g>
                            <g id="Group_6589" data-name="Group 6589" transform="translate(189.499 391.25)">
                              <path id="Path_4578" data-name="Path 4578" d="M180.1,378.412l3.9,7.8a.975.975,0,0,0,1.743-.872l-3.9-7.8a.975.975,0,1,0-1.743.872Z" transform="translate(-180 -377)" />
                            </g>
                            <g id="Group_6590" data-name="Group 6590" transform="translate(181.7 373.7)">
                              <path id="Path_4579" data-name="Path 4579" d="M176,376.775a.975.975,0,0,1,1.95,0v3.9a.975.975,0,1,1-1.95,0v-3.9Zm7.8-3.9a.975.975,0,1,1,1.95,0v7.8a.975.975,0,1,1-1.95,0v-7.8Zm7.8-3.9a.975.975,0,1,1,1.95,0v11.7a.975.975,0,1,1-1.95,0v-11.7Z" transform="translate(-176 -368)" />
                            </g>
                            <g id="Group_6591" data-name="Group 6591" transform="translate(170 362)">
                              <path id="Path_4580" data-name="Path 4580" d="M209,364.925a.975.975,0,0,0-.975-.975h-35.1a.975.975,0,0,0-.975.975v2.925H209v-2.925ZM172.925,362h35.1a2.926,2.926,0,0,1,2.925,2.925v3.9a.975.975,0,0,1-.975.975h-39a.975.975,0,0,1-.975-.975v-3.9A2.926,2.926,0,0,1,172.925,362Z" transform="translate(-170 -362)" />
                            </g>
                          </g>
                        </svg>
                        <h5> Performance Analysis</h5>
                      </a>
                    </li>
                    <li>
                      <a onClick={() => {
                        document.getElementById("svg5").setAttribute("fill", "#FFAE1F")
                        document.getElementById("svg1").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg2").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg3").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg4").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg6").setAttribute("fill", "#5c5c5c")
                      }} href="#name_tabs4" className="nav-link" data-toggle="tab">
                        {/* <i class="eso_schema" aria-label="eso_schema" role="img"></i> */}
                        <svg id='svg5' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="43.252" height="38.927" viewBox="0 0 43.252 38.927" fill="#5c5c5c">
                          <defs>
                            <clipPath id="clip-path">
                              <path id="Path_4588" data-name="Path 4588" d="M1678.707,145.325a1.081,1.081,0,1,0-1.081-1.081A1.081,1.081,0,0,0,1678.707,145.325Zm-6.488,6.488a1.081,1.081,0,1,0-1.081-1.081A1.081,1.081,0,0,0,1672.219,151.813Zm-6.488-6.488a1.081,1.081,0,1,0-1.081-1.081A1.081,1.081,0,0,0,1665.732,145.325Zm-6.488,6.488a1.081,1.081,0,1,0-1.081-1.081A1.081,1.081,0,0,0,1659.244,151.813Zm10.048-2.48-2.161-2.162a3.251,3.251,0,0,1-2.8,0l-2.161,2.162a3.242,3.242,0,1,1-1.529-1.529l2.161-2.162a3.244,3.244,0,1,1,5.855,0l2.161,2.162a3.251,3.251,0,0,1,2.8,0l2.161-2.162a3.242,3.242,0,1,1,1.529,1.529l-2.161,2.162a3.244,3.244,0,1,1-5.855,0Z" transform="translate(-1656 -141)" />
                            </clipPath>
                            <clipPath id="clip-path-2">
                              <path id="Path_4590" data-name="Path 4590" d="M1681.089,119.813h-36.764V117.65h38.927v27.032a3.244,3.244,0,0,1-3.244,3.244h-36.764a3.244,3.244,0,0,1-3.244-3.244V112.244a3.244,3.244,0,0,1,3.244-3.244h36.764a3.244,3.244,0,0,1,3.244,3.244v3.244h-2.163v-3.244a1.081,1.081,0,0,0-1.081-1.081h-36.764a1.081,1.081,0,0,0-1.081,1.081v32.439a1.081,1.081,0,0,0,1.081,1.081h36.764a1.081,1.081,0,0,0,1.081-1.081Z" transform="translate(-1640 -109)" />
                            </clipPath>
                            <clipPath id="clip-path-3">
                              <circle id="Ellipse_55" data-name="Ellipse 55" cx="1.5" cy="1.5" r="1.5" fill="#5c5c5c" />
                            </clipPath>
                          </defs>
                          <g id="Group_6600" data-name="Group 6600" transform="translate(-1640 -109)">
                            <g id="Group_6601" data-name="Group 6601" transform="translate(1648.65 126.301)">
                              <path id="Path_4587" data-name="Path 4587" d="M1678.707,145.325a1.081,1.081,0,1,0-1.081-1.081A1.081,1.081,0,0,0,1678.707,145.325Zm-6.488,6.488a1.081,1.081,0,1,0-1.081-1.081A1.081,1.081,0,0,0,1672.219,151.813Zm-6.488-6.488a1.081,1.081,0,1,0-1.081-1.081A1.081,1.081,0,0,0,1665.732,145.325Zm-6.488,6.488a1.081,1.081,0,1,0-1.081-1.081A1.081,1.081,0,0,0,1659.244,151.813Zm10.048-2.48-2.161-2.162a3.251,3.251,0,0,1-2.8,0l-2.161,2.162a3.242,3.242,0,1,1-1.529-1.529l2.161-2.162a3.244,3.244,0,1,1,5.855,0l2.161,2.162a3.251,3.251,0,0,1,2.8,0l2.161-2.162a3.242,3.242,0,1,1,1.529,1.529l-2.161,2.162a3.244,3.244,0,1,1-5.855,0Z" transform="translate(-1656 -141)" />
                            </g>
                            <g id="Group_6603" data-name="Group 6603" transform="translate(1640 109)">
                              <path id="Path_4589" data-name="Path 4589" d="M1681.089,119.813h-36.764V117.65h38.927v27.032a3.244,3.244,0,0,1-3.244,3.244h-36.764a3.244,3.244,0,0,1-3.244-3.244V112.244a3.244,3.244,0,0,1,3.244-3.244h36.764a3.244,3.244,0,0,1,3.244,3.244v3.244h-2.163v-3.244a1.081,1.081,0,0,0-1.081-1.081h-36.764a1.081,1.081,0,0,0-1.081,1.081v32.439a1.081,1.081,0,0,0,1.081,1.081h36.764a1.081,1.081,0,0,0,1.081-1.081Z" transform="translate(-1640 -109)" />
                            </g>
                            <g id="Group_6605" data-name="Group 6605" transform="translate(1644.492 113.492)">
                              <circle id="Ellipse_54" data-name="Ellipse 54" cx="1.5" cy="1.5" r="1.5" transform="translate(-0.017 -0.492)" />
                            </g>
                            <g id="Group_6607" data-name="Group 6607" transform="translate(1648.983 113.492)">
                              <circle id="Ellipse_56" data-name="Ellipse 56" cx="1.5" cy="1.5" r="1.5" transform="translate(0.491 -0.492)" />
                            </g>
                          </g>
                        </svg>
                        <h5> Schema Validator</h5>
                      </a>
                    </li>
                    <li>
                      <a onClick={() => {
                        document.getElementById("svg6").setAttribute("fill", "#FFAE1F")
                        document.getElementById("svg1").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg2").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg3").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg4").setAttribute("fill", "#5c5c5c")
                        document.getElementById("svg5").setAttribute("fill", "#5c5c5c")
                      }} href="#name_tabs5" className="nav-link" data-toggle="tab">
                        {/* <i class="eso_sitemap" aria-label="eso_sitemap" role="img"></i> */}
                        <svg id='svg6' xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="#5c5c5c">
                          <g id="Group_6599" data-name="Group 6599" transform="translate(-492 -493)">
                            <path id="Path_4585" data-name="Path 4585" d="M514.083,517.667V509h2.167v8.667h10.833a3.25,3.25,0,0,1,3.25,3.25v9.75h-2.167v-9.75a1.083,1.083,0,0,0-1.083-1.083H503.25a1.083,1.083,0,0,0-1.083,1.083v9.75H500v-9.75a3.25,3.25,0,0,1,3.25-3.25Z" transform="translate(-3.667 -7.333)" />
                            <path id="Path_4586" data-name="Path 4586" d="M514.75,498.417a3.25,3.25,0,1,0-3.25,3.25A3.25,3.25,0,0,0,514.75,498.417Zm2.167,0A5.417,5.417,0,1,1,511.5,493,5.417,5.417,0,0,1,516.917,498.417Zm-16.25,28.167a3.25,3.25,0,1,0-3.25,3.25A3.25,3.25,0,0,0,500.667,526.583Zm2.167,0a5.417,5.417,0,1,1-5.417-5.417A5.417,5.417,0,0,1,502.833,526.583Zm26,0a3.25,3.25,0,1,0-3.25,3.25A3.25,3.25,0,0,0,528.833,526.583Zm2.167,0a5.417,5.417,0,1,1-5.417-5.417A5.417,5.417,0,0,1,531,526.583Z" />
                          </g>
                        </svg>
                        <h5> Sitemap Generator</h5>
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    {/* Tab 1 */}
                    <div className="tab-pane active" id="name_tabs0">
                      <div className="row tabs_row_outer_wrap">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                          <div className="tabs_content_outer_wrap">
                            <h4>Track the rank position of your keywords across search engines and by location</h4>
                            <ul className="arrow_listing_outer_wrap">
                              <li>Check the preferred page’s rank status</li>
                              <li>Track a high-volume of keywords</li>
                              <li>Check keyword performance in Mobile searches</li>
                              <li>Compare with competitor’s rank</li>
                              <li>Track website’s visibility score</li>
                              <li>Compare search engine page-wise rank positions</li>
                              <li>Track search engine rank for web pages by section</li>
                              <li>Understand &amp; define strategies using keyword rank trends</li>
                            </ul>
                            <HashLink to="/features" elementId="root" className="explore_feature_outer_wrap">Explore All Features</HashLink>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                          <div className="what_we_do_img_outer_wrap">
                            <img src={Rank_Analysis} alt="Rank Analysis lap" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="name_tabs1">
                      <div className="row tabs_row_outer_wrap">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                          <div className="tabs_content_outer_wrap">
                            <h4>Check your link metrics, identify and fix link errors</h4>
                            <ul className="arrow_listing_outer_wrap">
                              <li>Monitor for 4xx and 5xx link errors</li>
                              <li>Check &amp; validate redirected links</li>
                              <li>Crawls links up to 10 levels deep</li>
                              <li>Validate HTML, image (src), CSS, JS etc, link types</li>
                              <li>Crawl a protected STAGING or QA environment</li>
                              <li>Analyze using source &amp; outbound links reports</li>
                              <li>Check the status of a large volume of links</li>
                              <li>Request custom crawl after major changes are made</li>
                            </ul>
                            <HashLink to="/features" className="explore_feature_outer_wrap" elementId='root'>Explore All Features</HashLink>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                          <div className="what_we_do_img_outer_wrap">
                            <img src={Link_Checker} alt="Internal Links lap" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="name_tabs2">
                      <div className="row tabs_row_outer_wrap">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                          <div className="tabs_content_outer_wrap">
                            <h4>Identify your On-page SEO strengths &amp; weaknesses</h4>
                            <ul className="arrow_listing_outer_wrap">
                              <li>Crawl a large volume of web pages</li>
                              <li>Extract SEO elements to find missing &amp; duplicate elements</li>
                              <li>Compare on-page SEO data with competitors</li>
                              <li>Find Missing and Mismatched canonical URLs</li>
                              <li>Verify Pagination &amp; hreflang directives</li>
                              <li>Set your own priorities for SEO issues</li>
                              <li>Get Page-wise SEO health reports</li>
                              <li>Compare previous crawls, STAGING &amp; QA environment</li>
                            </ul>
                            <HashLink to="/features" className="explore_feature_outer_wrap" elementId='root'>Explore All Features</HashLink>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                          <div className="what_we_do_img_outer_wrap">
                            <img src={On_page_data} alt="On Page Analysis lap" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="name_tabs3">
                      <div className="row tabs_row_outer_wrap">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                          <div className="tabs_content_outer_wrap">
                            <h4>Monitor your pages for Technical SEO audits, performance, etc.,</h4>
                            <ul className="arrow_listing_outer_wrap">
                              <li>Test W3C, WCAG, ARIA accessibility</li>
                              <li>Check Mobile accessibility score</li>
                              <li>Track User Experience scores of landing pages</li>
                              <li>Assess coding standards of web pages</li>
                              <li>Check AMP compliance, issues &amp; remedies</li>
                              <li>Check to fix Page load time, speed, image optimization</li>
                              <li>Validate URL accessibility &amp; Page SEO metrics</li>
                              <li>White-labeled detailed audit report</li>
                            </ul>
                            <HashLink to="/features" className="explore_feature_outer_wrap">Explore All Features</HashLink>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                          <div className="what_we_do_img_outer_wrap">
                            <img src={Performance} alt="Performance Analysis lap" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="name_tabs4">
                      <div className="row tabs_row_outer_wrap">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                          <div className="tabs_content_outer_wrap">
                            <h4>Boost your website visibility with Rich snippets</h4>
                            <ul className="arrow_listing_outer_wrap">
                              <li>Crawl and scan Schema markups on all pages</li>
                              <li>Validate JSON-LD, Microdata &amp; RDFa formats</li>
                              <li>Find missing properties and attributes</li>
                              <li>Validate for most entity types</li>
                              <li>Eliminates manual validation &amp; speeds up auditing</li>
                              <li>Beautiful PDF white-label reports</li>
                            </ul>
                            <HashLink to="/features" elementId='root' className="explore_feature_outer_wrap">Explore All Features</HashLink>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                          <div className="what_we_do_img_outer_wrap">
                            <img src={schema_validator} alt="Schema Validator lap" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="name_tabs5">
                      <div className="row tabs_row_outer_wrap">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                          <div className="tabs_content_outer_wrap">
                            <h4>Help Search engine bots to crawl your important pages</h4>
                            <ul className="arrow_listing_outer_wrap">
                              <li>Generate XML, HTML, Image and Video sitemap</li>
                              <li>Generate multiple sitemaps for XML, image &amp; video</li>
                              <li>Get sitemap index files for all your sitemaps</li>
                              <li>Follows guidelines for URLs &amp; file size limits</li>
                              <li>Reduce the domain’s crawl budget by bots</li>
                              <li>Improve your website's visibility in Image searches</li>
                              <li>Provide meta-information for images to search engines</li>
                              <li>Boost your web page ranking in video searches</li>
                            </ul>
                            <HashLink to="/features" className="explore_feature_outer_wrap">Explore All Features</HashLink>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                          <div className="what_we_do_img_outer_wrap">
                            <img src={Site_map} alt="Sitemap Generator lap" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Tab 1 */}
                    {/* Tab 2 */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Tabs */}
          {/* Solutions */}
          <Suspense fallback={<RotatingLines
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }}
            strokeColor="#052A53"
            strokeWidth="5"
            animationDuration="0.75"
            width="45"
            visible={true}
          />}>
            <Slider />
          </Suspense>
          {/* End Solution */}
          
          {/* Strategies */}
          <div className="strategies_bgouter_wrap">
            <div className="container">
              <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-7">
                  <div className="strategies_left_content_wrap">
                    <h2>Strategies</h2>
                    <h3><span>Elite Site Optimizer</span> Offers Data-Driven Strategies To Improve Your Site</h3>
                    <p><span style={{ fontWeight: 400 }}> Take advantage of this integrated business SEO application data to understand your webpage health, URL accessibility, user experience metrics, content quality, PDF-related accessibility issues, and 508 compliance. Generate reports for actionable insights that will <HashLink to="/services/digital-marketing-services/" target="_blank" rel="noopener">boost your site’s</HashLink> traffic and increase user engagement.</span></p>
                    <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-5">
                  <div className="strategies_img_outer_wrap">
                    <img src={ESO_offer} alt="Strategies" />
                  </div>
                </div>
              </div>
              <div className="row strategies_methods_row_wrap">
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="strategies_methods_outer_wrap">
                    <div className="strategies_method_icon_wrap">
                      <img src={digital_marketingicon} alt="startsup" />
                    </div>
                    <h4>For Small and Medium Businesses &amp; Start ups</h4>
                    <p> We help small businesses and startups to analyze their website performance. We provide custom reports on their traffic and ranking positions based on their requirements.</p>
                    {/*    <a href="#">Know More</a> */}
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="strategies_methods_outer_wrap">
                    <div className="strategies_method_icon_wrap">
                      <img src={optimization} alt="startsup" />
                    </div>
                    <h4>For SEO Professionals</h4>
                    <p> We help SEO professionals find the right data, at the right time, to take informed decisions with our SEO audit tool. It identifies on-page issues, link issues, content quality issues, performance issues, and keyword positions.</p>
                    {/*    <a href="#">Know More</a> */}
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="strategies_methods_outer_wrap">
                    <div className="strategies_method_icon_wrap">
                      <img src={banner1} alt="startsup" />
                    </div>
                    <h4>For Digital Marketing Agencies</h4>
                    <p> We help SEO agencies set up campaigns for multiple keywords and build white-labeled SEO reports for their clients with less effort.</p>
                    {/*    <a href="#">Know More</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Strategies */}
          {/* Optimize your website */}
          <div className="tabs_bg_outer_wrap">
            <div className="container">
              <div className="row optimize_your_website_row_wrap">
                <div className="col-xl-7 col-lg-7 col-md-6">
                  <div className="what_we_do_img_outer_wrap">
                    <img src={bg_2} alt="what_we_do_bg_wrap" />
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-6">
                  <div className="what_we_do_content_outer_wrap">
                    <div className="common_title_outer_wrap">
                      <h2>STRATEGIES</h2>
                      <h3><span style={{ fontWeight: 600 }}>Optimize</span> Your <br />Website Today</h3>
                    </div>
                    <p>Contact us to get your free audit of your website</p>
                    <HashLink to="/contact-us/?subject=free-trial-request" elementId="contact_form_test" className="explore_feature_outer_wrap">Get Your Free Trial</HashLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Optimize your website */}
          {/* Latest SEO */}
          <div className="container">
            <div className="row seo_title_row_outer_wrap">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="common_title_outer_wrap">
                  <h2>latest Seo</h2>
                  <h4>Tips &amp; Tricks</h4>
                  <h3>From Our Blogs</h3>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="see_all_blog_outer_wrap">
                  {/*      <a href="#">Hottest Topics</a>
                <a href="#">See All Our Blog</a> */}
                </div>
              </div>
            </div>
            <div className="home_blog_outer_wrap">
              {/* Blog 1 */}
              {/* {recentBlogData.map((items, index) => {
                return (
                  <div className="home_blog_inner_wrap">
                    <h3>{items.title}</h3>
                    <div className="blog_date_wrap">
                      <h4 />
                      <span>|</span>
                      <h4 id='blog-date' className="blog_date_inner_wrap">{items.created_at.substring(0, 10)}
                      </h4>
                    </div>
                    <div className="bnt-content-cus">
                      <p style={{ fontSize: '14px' }}>{(parse(items.page_content))}</p>
                    </div>
                    <HashLink to={`/blog/${items.url_slug}`} elementId='root'>Read More</HashLink>
                    <div className="home_blog_img_wrap">
                      <img src={solution_img3} alt="blog_img" />
                    </div>
                  </div>
                )
              })} */}
              <Suspense fallback={<RotatingLines
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
                strokeColor="#052A53"
                strokeWidth="5"
                animationDuration="0.75"
                width="45"
                visible={true}
              />}>
                <HomeBlogs />
              </Suspense>

            </div>
          </div>
          {/* End Latest SEO */}
          {/* CTA */}
          <div className="cta_bg_wrap_home">
            <div className="cta_bg_img_left_outer_wrap">
              <img src={bg_4} alt="CTA_bg" />
            </div>
            <div className="cta_bg_img_right_outer_wrap">
              <img src={bg_3} alt="CTA_bg" />
            </div>
            <div className="container">
              <div className="cta_content_outer_wrap">
                <h4><span>Let’s Get Started,</span><br />Contact The Experts <br />And Boost Your SEO Today!</h4>
                <HashLink to="/contact-us/?subject=free-trial-request" elementId="contact_form_test">Get Your Free Trial</HashLink>
              </div>
            </div>
          </div>
        </div>
        {/* #et-main-area */}
        {/* <div className="main-addon hide-overlay">
          <div className="overlay">
            <div className="overlay-content">
              <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
              <iframe title="support" src={`${process.env.REACT_APP_DOMAIN}/addon/kb/`} />
            </div>
          </div>
          <div className="buttons-container">
            <button className="button supportBTN"><i className="fa fa-search" />Support</button>
          </div>
        </div>  */}
        {/*Start of Zendesk Chat Script*/}
        {/*End of Zendesk Chat Script*/}{/*'}'*/}
        {/* <div class="cta_bg_wrap">
        <div class="cta_bg_img_left_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_4.png" alt="CTA_bg"> </div>
        <div class="cta_bg_img_right_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_3.png" alt="CTA_bg"> </div>
        <div class="container">
            <div class="cta_content_outer_wrap">
                <h4><span>Schedule A Meeting</span> To Take<br>Your Business To The Next Level.</h4> <a href="#">Get Your Free Trial</a> </div>
        </div>
    </div> */}
        {/* #main-footer */}
        {/* #page-container */}
        {getCookieAgree ? '' : <CookiesPopup />}
      </div>
    </div>
  );
}

export default Home;
