import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import './signup.css';
import { Helmet } from 'react-helmet';

// import 'font-awesome/css/font-awesome.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// 

const Signup = () => {

    useEffect(() => {
        document.title = 'Plans and Pricing - SEO Automation Tool';
    }, []);

    const [isActive, setActive] = useState(true);

    const onClick = () => {
        // if (isActive == true){
        //     setActive (!isActive);
        // }
        setActive(!isActive);
    };

    return (
        <div>
        <Helmet>
        <meta
          name="description"
          content="Explore Elite Site Optimizer's plans and pricing. Choose the best package to enhance your website's performance with our powerful SEO tools."
        />
       </Helmet>
            {/* #main-header */}
            <div id="et-main-area">
                {/*Google Tag Manager (noscript) */}
                {/* Google Tag Manager (noscript) */}
                <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
                    height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
                {/* End Google Tag Manager (noscript) */}
                {/* <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/plans.css" type="text/css" media="all" async /> */}
                {/* End Header */}
                {/* Page Title */}
                <div className="container-fluid pl-0 pr-0 page_title_outer_wrap">
                    <div className="container">
                        <div className="page_title_img_wrap">
                            <img src="https://www.elitesiteoptimizer.com/assets/eso/images/home_image/plans_price_banner.png" alt="plans_price_banner" />
                        </div>
                        <h2>PLANS AND PRICING</h2>
                        <h3>Find A Plan</h3>
                        <h3>That’s Right For You</h3>
                    </div>
                </div>
                {/* End Page Title */}
                <div className="plans-n-pricing top_padding_50 plans_pricing_outer_wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 padding_none_md">
                                <div className="common_text_inner common_center">
                                    <h1>Plans and Pricing</h1>
                                </div>
                                <div className="common_text_inner row top_padding_30 start-trial-row">
                                    <div className="col-lg-6 col-md-6">
                                        <HashLink className="trial-btn" to="/contact-us/?subject=custom-plan-request" elementId="contact_form_test">Start my 14 days Free Trial</HashLink>
                                    </div>
                                    <div className="col-lg-6 col-md-6 common_left">
                                        <div className="pricing-btn">
                                            <div>
                                                <a id="mo" className="class_mo"><span onClick={onClick} data-plan="month-data" className={isActive ? "mo pricing_plan month-data-tag" : "mo pricing_plan month-data-tag active"}>Monthly</span></a>
                                            </div>
                                            <div>
                                                <a id="yr" className="class_yr"><span onClick={onClick} data-plan="year-data" className={isActive ? "yr pricing_plan year-data-tag active" : "yr pricing_plan year-data-tag"}>Yearly</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="pricing" className={isActive ? "pricing pricing_slider1 pricing_slider all_plans_data month-data row top_padding_50 bottom_padding_50 top_margin_20" : "pricing pricing_slider1 pricing_slider all_plans_data month-data row top_padding_50 bottom_padding_50 top_margin_20 active"}>
                                    <div className={isActive ? "col-md-4 pl-0 pr-0 col-sm-12 all_plans_data  month-data Basicmonth-class active" : "col-md-4 pl-0 pr-0 col-sm-12 all_plans_data  month-data Basicmonth-class"}>
                                        <div className="basicall">
                                            {/* <div class="common_image_wrap" style="display:none;">
                         <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/pricing/tag-png-e1577774935442.png" alt="Elitesiteoptimizer - custom solution" title="Elitesiteoptimizer - custom solution" /></div> */}
                                            <div className="common_text_inner">
                                                <div className="basic">
                                                    <h2 className="common_center">Basic</h2>
                                                </div>
                                                <h3 className="common_center"><span className="dolr">$</span><b style={{}}>49</b> / month</h3>
                                                <p className="common_center"><i>$490 if billed yearly</i></p>
                                            </div>
                                            <div className="common_center">
                                                {/* <a className="btn_subcribte" href="https://products.egrovesys.com/checkout/?layout=" tabIndex={0}>SUBSCRIBE</a> */}
                                                <HashLink to="/contact-us/?subject=basic-monthly-plan-request" elementId="contact_form_test" className='btn_subcribte'>CONTACT US</HashLink>
                                            </div>
                                            <div className="common_text_inner top_padding_30 bottom_padding_30">
                                                <p style={{ textAlign: 'center' }}>Recommended plan for aspiring SEO enthusiasts, bloggers, individual website owner &amp; startup businesses etc..</p>
                                            </div>
                                            {/*  <div class="bgcolor"></div> */}
                                        </div>
                                        <div className="common_text_inner common_center top_padding_15">
                                            <a href="#ComparePlans" className="newclor" style={{ color: '#062848' }} tabIndex={0}>  Compare plans</a>
                                        </div>  
                                    </div>
                                    <div className={isActive ? "col-md-4 pl-0 pr-0 col-sm-12 all_plans_data  month-data Promonth-class" : "col-md-4 pl-0 pr-0 col-sm-12 all_plans_data  month-data Promonth-class active"}>
                                        <div className="basicall">
                                            {/* <div class="common_image_wrap" style="display:none;">
                         <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/pricing/tag-png-e1577774935442.png" alt="Elitesiteoptimizer - custom solution" title="Elitesiteoptimizer - custom solution" /></div> */}
                                            <div className="common_text_inner">
                                                <div className="basic">  
                                                    <h2 className="common_center">Pro</h2>
                                                </div>
                                                <h3 className="common_center"><span className="dolr">$</span><b style={{}}>99</b> / month</h3>
                                                <p className="common_center"><i>$990 if billed yearly</i></p>
                                            </div>
                                            <div className="common_center">
                                                {/* <a className="btn_subcribte" href="https://products.egrovesys.com/checkout/?layout=" tabIndex={0}>SUBSCRIBE</a> */}
                                           <HashLink to="/contact-us/?subject=pro-monthly-plan-request" elementId="contact_form_test" className='btn_subcribte'>CONTACT US</HashLink>
                                            </div>
                                            <div className="common_text_inner top_padding_30 bottom_padding_30">
                                                <p style={{ textAlign: 'center' }}>Most popular and suited plan for small businesses, freelance digital marketers, web masters etc..</p>
                                            </div>
                                            {/*  <div class="bgcolor"></div> */}
                                        </div>
                                        <div className="common_text_inner common_center top_padding_15">
                                            <a href="#ComparePlans" className="newclor" style={{ color: '#062848' }} tabIndex={0}>  Compare plans</a>
                                        </div>
                                    </div>
                                    <div className="col-md-4 pl-0 pr-0 col-sm-12 all_plans_data  enterprise-data Enterpriseenterprise-class">
                                        <div className="basicall">
                                            {/* <div class="common_image_wrap" style="display:none;">
                         <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/pricing/tag-png-e1577774935442.png" alt="Elitesiteoptimizer - custom solution" title="Elitesiteoptimizer - custom solution" /></div> */}
                                            <div className="common_text_inner">
                                                <div className="basic">
                                                    <h2 className="common_center">Enterprise</h2>
                                                </div>
                                                <h3 className="common_center">For pricing offers</h3>
                                            </div>
                                            <div className="common_center">
                                                <HashLink to="/contact-us/?subject=custom-plan-request" elementId="contact_form_test" className='btn_subcribte'>CONTACT US</HashLink>
                                            </div>
                                            <div className="common_text_inner top_padding_30 bottom_padding_30">
                                                <p style={{ textAlign: 'center' }}>Best suggested plan for large data companies, SEO agencies &amp; businesses that requires custom options.</p>
                                            </div>
                                            {/*  <div class="bgcolor"></div> */}
                                        </div>
                                        <div className="common_text_inner common_center top_padding_15">
                                            <a href="#ComparePlans" className="newclor" style={{ color: '#062848' }} tabIndex={0}>  Compare plans</a>
                                        </div>
                                    </div>
                                </div>
                                <div id="pricing" className={isActive ? "pricing pricing_slider2 pricing_slider all_plans_data year-data row top_padding_50 top_margin_20 bottom_padding_50 active" : "pricing pricing_slider2 pricing_slider all_plans_data year-data row top_padding_50 top_margin_20 bottom_padding_50"}>
                                    <div className={isActive ? "col-md-4 pl-0 pr-0 col-sm-12 all_plans_data  year-data Basicyear-class active" : "col-md-4 pl-0 pr-0 col-sm-12 all_plans_data  year-data Basicyear-class"}>
                                        <div className="basicall">
                                            {/* <div class="common_image_wrap" style="display:none;">
                         <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/pricing/tag-png-e1577774935442.png" alt="Elitesiteoptimizer - custom solution" title="Elitesiteoptimizer - custom solution" /></div> */}
                                            <div className="common_text_inner">
                                                <div className="basic">
                                                    <h2 className="common_center">Basic</h2>
                                                </div>
                                                <h3 className="common_center"><span className="dolr">$</span><b style={{}}>490</b> / year</h3>
                                                <p className="common_center"><i>$49 if billed monthly</i></p>
                                            </div>
                                            <div className="common_center">
                                                {/* <a className="btn_subcribte" href="https://products.egrovesys.com/checkout/?layout=" tabIndex={0}>SUBSCRIBE</a> */}
                                                <HashLink to="/contact-us/?subject=basic-yearly-plan-request" elementId="contact_form_test" className='btn_subcribte'>CONTACT US</HashLink>

                                            </div>
                                            <div className="common_text_inner top_padding_30 bottom_padding_30">
                                                <p style={{ textAlign: 'center' }}>Recommended plan for aspiring SEO enthusiasts, bloggers, individual website owner &amp; startup businesses etc..</p>
                                            </div>
                                            {/*  <div class="bgcolor"></div> */}
                                        </div>
                                        <div className="common_text_inner common_center top_padding_15">
                                            <a href="#ComparePlans" className="newclor" style={{ color: '#062848' }} tabIndex={0}>  Compare plans</a>
                                        </div>
                                    </div>
                                    <div className={isActive ? "col-md-4 pl-0 pr-0 col-sm-12 all_plans_data  year-data Proyear-class active" : "col-md-4 pl-0 pr-0 col-sm-12 all_plans_data  year-data Proyear-class"}>
                                        <div className="basicall">
                                            {/* <div class="common_image_wrap" style="display:none;">
                         <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/pricing/tag-png-e1577774935442.png" alt="Elitesiteoptimizer - custom solution" title="Elitesiteoptimizer - custom solution" /></div> */}
                                            <div className="common_text_inner">
                                                <div className="basic">
                                                    <h2 className="common_center">Pro</h2>
                                                </div>
                                                <h3 className="common_center"><span className="dolr">$</span><b style={{}}>990</b> / year</h3>
                                                <p className="common_center"><i>$99 if billed monthly</i></p>
                                            </div>
                                            <div className="common_center">
                                                {/* <a className="btn_subcribte" href="https://products.egrovesys.com/checkout/?layout=" tabIndex={0}>SUBSCRIBE</a> */}
                                                <HashLink to="/contact-us/?subject=pro-yearly-plan-request" elementId="contact_form_test" className='btn_subcribte'>CONTACT US</HashLink>
 
                                            </div>
                                            <div className="common_text_inner top_padding_30 bottom_padding_30">
                                                <p style={{ textAlign: 'center' }}>Most popular and suited plan for small businesses, freelance digital marketers, web masters etc..</p>
                                            </div>
                                            {/*  <div class="bgcolor"></div> */}
                                        </div>
                                        <div className="common_text_inner common_center top_padding_15">
                                            <a href="#ComparePlans" className="newclor" style={{ color: '#062848' }} tabIndex={0}>  Compare plans</a>
                                        </div>
                                    </div>
                                    <div className="col-md-4 pl-0 pr-0 col-sm-12 all_plans_data  enterprise-data Enterpriseenterprise-class">
                                        <div className="basicall">
                                            {/* <div class="common_image_wrap" style="display:none;">
                         <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/pricing/tag-png-e1577774935442.png" alt="Elitesiteoptimizer - custom solution" title="Elitesiteoptimizer - custom solution" /></div> */}
                                            <div className="common_text_inner">
                                                <div className="basic">
                                                    <h2 className="common_center">Enterprise</h2>
                                                </div>
                                                <h3 className="common_center">For pricing offers</h3>
                                            </div>
                                            <div className="common_center">
                                                <HashLink to="/contact-us/?subject=custom-plan-request" elementId="contact_form_test" className="btn_subcribte" tabIndex={0}>CONTACT US</HashLink>
                                            </div> 
                                            <div className="common_text_inner top_padding_30 bottom_padding_30">
                                                <p style={{ textAlign: 'center' }}>Best suggested plan for large data companies, SEO agencies &amp; businesses that requires custom options.</p>
                                            </div>
                                            {/*  <div class="bgcolor"></div> */}
                                        </div>
                                        <div className="common_text_inner common_center top_padding_15">
                                            <a href="#ComparePlans" className="newclor" style={{ color: '#062848' }} tabIndex={0}>  Compare plans</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row col-12 top_padding_30 bottom_padding_50 bottom_margin_30">
                                <div className="col-md-6 col-sm-12">
                                    <div className="commone_image_wrap common_center"> <img src="https://www.elitesiteoptimizer.com/str/uploads/payment_methods_85a19c782e.png" alt="Payment Methods" title style={{margin:'auto'}}/></div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="commone_image_wrap common_center"> <img src="https://www.elitesiteoptimizer.com/str/uploads/ssl_secure_22c3e5236c.png" alt="Payment Methods" title style={{margin:'auto'}}/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="price_planing_bg_outer_wrap">
                    <div className="container">
                        <div className="row what_we_do_row_wrap">
                            <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="what_we_do_content_outer_wrap">
                                    <div className="common_title_outer_wrap">
                                        <h3>Looking For A <span>Custom Solution</span> And Need More?</h3>
                                    </div>
                                    <ul className="arrow_listing_outer_wrap">
                                        <li>Do you have large data set or bigger website?</li>
                                        <li>Are you looking for only selected features? (For Example: Only Rank tracking or only Link analysis)</li>
                                        <li>Do you require other combination of features? Elite Site Optimizer can accommodate to your business need.</li>
                                    </ul>
                                    <HashLink to="/contact-us/?subject=custom-plan-request" elementId="contact_form_test">Request Free Trial</HashLink>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="what_we_do_img_outer_wrap">
                                    <img src="https://www.elitesiteoptimizer.com/assets/eso/images/home_image/price_info_bg.png" alt="price_info_bg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Contact Form */}
                <div className="elite_optimizer_advantage_bg_wrap">
                    <div className="container">
                        <div className="elite_optimizer_advantage_content_wrap">
                            <div className="elite_optimizer_advantage_left_wrap">
                                <h3>Elite Site Optimizer <span>Advantage</span></h3>
                            </div>
                            <div className="elite_optimizer_advantage_right_wrap">
                                <h5>Easy On Boarding And One-To-One Guidance</h5>
                                <h5>Recommendations That Your Business Needs</h5>
                                <h5>Excellent Support Team Who Knows Seo</h5>
                                <h5>Thorough Knowledge Base &amp; Industry Updates</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="compare-plans desktop_viewcompare top_padding_50 bottom_padding_50">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className>
                                    <div className="common_text_inner">
                                        <h2 className="common_center">Compare Plans</h2>
                                        <h5 className="common_center sub_title">Every ESO plans include important audit tools and reports to optimize your website and keep you ahead of your competitors. Select the right plan to suit your business needs.</h5>
                                    </div>
                                    <div className="common_text_inner" id="ComparePlans">
                                        <div id="tablepress-1_wrapper" className="dataTables_wrapper no-footer top_padding_30">
                                            <table id="tablepress-1" className="tablepress tablepress-id-1_cus dataTable no-footer stickme" role="grid">
                                                <thead className="task_flyout">
                                                    <tr className="row-1 odd" role="row">
                                                        <th className="column-1 sorting_disabled" rowSpan={1} colSpan={1} style={{}}>
                                                            <div className="common_text_inner">
                                                                <div className="pricing-btn compare">
                                                                    <div>
                                                                        <a id="mo" className="class_mo"><span data-plan="month-data" onClick={onClick} className={isActive ? "mo pricing_plan month-data-tag" : "mo pricing_plan month-data-tag active"}>Monthly</span></a>
                                                                    </div>
                                                                    <div>
                                                                        <a id="yr" className="class_yr"><span data-plan="year-data" onClick={onClick} className={isActive ? "yr pricing_plan year-data-tag active" : "yr pricing_plan year-data-tag"}>Yearly</span></a>
                                                                    </div>
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className={isActive ? "column-2 sorting_disabled all_plans_data month-data Basicmonth-class" : "column-2 sorting_disabled all_plans_data month-data Basicmonth-class active"} rowSpan={1} colSpan={1} style={{}}>
                                                            <div className="details">
                                                                <h2>Basic</h2>
                                                                <div className>
                                                                    <h3><span className="dolr">$</span><b style={{}}>49</b> / month</h3>
                                                                    <h4><i>$490 if billed yearly</i></h4>
                                                                    <a href="https://products.egrovesys.com/checkout/?layout=" className="basic-month"><span className="btn bas">SUBSCRIBE</span></a>
                                                                </div>
                                                            </div>
                                                            <br />
                                                        </th>
                                                        <th className={isActive ? "column-2 sorting_disabled all_plans_data month-data Promonth-class" : "column-2 sorting_disabled all_plans_data month-data Promonth-class active"} rowSpan={1} colSpan={1} style={{}}>
                                                            <div className="details">
                                                                <h2>Pro</h2>
                                                                <div className>
                                                                    <h3><span className="dolr">$</span><b style={{}}>99</b> / month</h3>
                                                                    <h4><i>$990 if billed yearly</i></h4>
                                                                    <a href="https://products.egrovesys.com/checkout/?layout=" className="basic-month"><span className="btn bas">SUBSCRIBE</span></a>
                                                                </div>
                                                            </div>
                                                            <br />
                                                        </th>
                                                        <th className={isActive ? "column-2 sorting_disabled  all_plans_data  year-data Basicyear-class active" : "column-2 sorting_disabled  all_plans_data  year-data Basicyear-class"} rowSpan={1} colSpan={1} style={{}}>
                                                            <div className="details">
                                                                <h2>Basic</h2>
                                                                <div className>
                                                                    <h3><span className="dolr">$</span><b style={{}}>490</b> / year</h3>
                                                                    <h4><i>$49 if billed monthly</i></h4>
                                                                    <a href="https://products.egrovesys.com/checkout/?layout=" className="basic-month"><span className="btn bas">SUBSCRIBE</span></a>
                                                                </div>
                                                            </div>
                                                            <br />
                                                        </th>
                                                        <th className={isActive ? "column-2 sorting_disabled  all_plans_data  year-data Proyear-class active" : "column-2 sorting_disabled  all_plans_data  year-data Proyear-class"} rowSpan={1} colSpan={1} style={{}}>
                                                            <div className="details">
                                                                <h2>Pro</h2>
                                                                <div className>
                                                                    <h3><span className="dolr">$</span><b style={{}}>990</b> / year</h3>
                                                                    <h4><i>$99 if billed monthly</i></h4>
                                                                    <a href="https://products.egrovesys.com/checkout/?layout=" className="basic-month"><span className="btn bas">SUBSCRIBE</span></a>
                                                                </div>
                                                            </div>
                                                            <br />
                                                        </th>
                                                        <th className="column-2 sorting_disabled  all_plans_data  enterprise-data Enterpriseenterprise-class " rowSpan={1} colSpan={1} style={{}}>
                                                            <div className="details">
                                                                <h2>Enterprise</h2>
                                                                <div className>
                                                                    <h3>For pricing offers</h3>
                                                                    <HashLink to="/contact-us/?subject=custom-plan-request" elementId='root' className="basic-month"><span className="btn bas">CONTACT US</span></HashLink>
                                                                </div>
                                                            </div>
                                                            <br />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="row-hover">
                                                    <tr className="row-2 even" role="row">
                                                        <td className="column-1">Domains, Crawl &amp; Users</td>
                                                        <td className="column-2" />
                                                        <td className="column-3" />
                                                        <td className="column-4" />
                                                    </tr>
                                                    <tr className="row-3 odd" role="row">
                                                        <td className="column-1">
                                                            No. of Domains
                                                            <div className="tooltip">ⓘ<span className="tooltiptext">The total number of website domains that can be added to the organization account.</span></div>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">5 Domains</td>
                                                        <td className="column-2 all_plans_data month-data active">10 Domains</td>
                                                        <td className="column-2 all_plans_data  year-data">5 Domains</td>
                                                        <td className="column-2 all_plans_data  year-data">10 Domains</td>
                                                        <td className="column-2 all_plans_data  enterprise-data">50 Domains</td>
                                                    </tr>
                                                    <tr className="row-4 even" role="row">
                                                        <td className="column-1">
                                                            No. of Campaigns
                                                            <div className="tooltip">ⓘ<span className="tooltiptext">The total number of campaigns or keyword groups that can be added to the organization account for position tracking.</span></div>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">10 Campaigns</td>
                                                        <td className="column-2 all_plans_data month-data active">20 Campaigns</td>
                                                        <td className="column-2 all_plans_data  year-data">10 Campaigns </td>
                                                        <td className="column-2 all_plans_data  year-data">20 Campaigns </td>
                                                        <td className="column-2 all_plans_data  enterprise-data">50 Campaigns</td>
                                                    </tr>
                                                    <tr className="row-5 odd" role="row">
                                                        <td className="column-1">
                                                            No. of Keywords
                                                            <div className="tooltip">ⓘ<span className="tooltiptext">
                                                                The total number of keywords that can be added to the organization account for position tracking. One keyword = 1 original keyword x 1 search engine x 1 location.
                                                            </span>
                                                            </div>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">1,000 Keywords</td>
                                                        <td className="column-2 all_plans_data month-data active">2,000 Keywords</td>
                                                        <td className="column-2 all_plans_data  year-data">1,000 Keywords </td>
                                                        <td className="column-2 all_plans_data  year-data">2,000 Keywords </td>
                                                        <td className="column-2 all_plans_data  enterprise-data">5,000 Keywords</td>
                                                    </tr>
                                                    <tr className="row-6 even" role="row">
                                                        <td className="column-1">
                                                            Max. no. of Web Pages for Crawl
                                                            <div className="tooltip">ⓘ<span className="tooltiptext">The total number of web pages or URLs that you can crawl for all your projects.</span></div>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">5,000 Web pages </td>
                                                        <td className="column-2 all_plans_data month-data active">10,000 Web pages</td>
                                                        <td className="column-2 all_plans_data  year-data">5,000 Web pages</td>
                                                        <td className="column-2 all_plans_data  year-data">10,000 Web pages </td>
                                                        <td className="column-2 all_plans_data  enterprise-data">75,000 Web pages</td>
                                                    </tr>
                                                    <tr className="row-7 odd" role="row">
                                                        <td className="column-1">
                                                            Spell check URLs
                                                            <div className="tooltip">ⓘ<span className="tooltiptext">The total number of webpages or URLs that can be uploaded for spell checking at a time.</span></div>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">149 URLs</td>
                                                        <td className="column-2 all_plans_data month-data active">249 URLs</td>
                                                        <td className="column-2 all_plans_data  year-data">149 URLs </td>
                                                        <td className="column-2 all_plans_data  year-data">249 URLs </td>
                                                        <td className="column-2 all_plans_data  enterprise-data">249 URLs</td>
                                                    </tr>
                                                    <tr className="row-8 even" role="row">
                                                        <td className="column-1">
                                                            No. of Users
                                                            <div className="tooltip">ⓘ<span className="tooltiptext">The total number of users allowed for your organization account for account delegation.</span></div>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">1 User</td>
                                                        <td className="column-2 all_plans_data month-data active">3 Users </td>
                                                        <td className="column-2 all_plans_data  year-data">1 User </td>
                                                        <td className="column-2 all_plans_data  year-data">3 Users</td>
                                                        <td className="column-2 all_plans_data  enterprise-data">10 Users</td>
                                                    </tr>
                                                    <tr className="row-9 odd" role="row">
                                                        <td className="column-1">
                                                            No. of Competitors
                                                            <div className="tooltip">ⓘ<span className="tooltiptext">The total number of competitors that can be added per domain in your account for comparison.</span></div>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">3 Competitors</td>
                                                        <td className="column-2 all_plans_data month-data active">5 Competitors </td>
                                                        <td className="column-2 all_plans_data  year-data">3 Competitors</td>
                                                        <td className="column-2 all_plans_data  year-data">5 Competitors</td>
                                                        <td className="column-2 all_plans_data  enterprise-data">5 Competitors</td>
                                                    </tr>
                                                    <tr className="row-10 even" role="row">
                                                        <td className="column-1">Position Tracking</td>
                                                        <td className="column-2" />
                                                        <td className="column-3" />
                                                        <td className="column-4" />
                                                    </tr>
                                                    <tr className="row-11 odd" role="row">
                                                        <td className="column-1">
                                                            Mobile Rank
                                                            <div className="tooltip">ⓘ<span className="tooltiptext">The ability to check mobile keyword ranking in the Rank checker tool.</span></div>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  year-data">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  year-data">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  enterprise-data">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                    </tr>
                                                    <tr className="row-12 even" role="row">
                                                        <td className="column-1">
                                                            Zeroth Rank/ Featured Snippet
                                                            <div className="tooltip">ⓘ<span className="tooltiptext">The ability to track the keyword's performance for SERP featured snippet. </span></div>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">
                                                            <span className="tick_icon no">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  year-data">
                                                            <span className="tick_icon no">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  year-data">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  enterprise-data">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                    </tr>
                                                    <tr className="row-13 odd" role="row">
                                                        <td className="column-1">Opportunities, Backlink &amp; Instant Audit</td>
                                                        <td className="column-2" />
                                                        <td className="column-3" />
                                                        <td className="column-4" />
                                                    </tr>
                                                    <tr className="row-14 even" role="row">
                                                        <td className="column-1">
                                                            Missed Opportunities
                                                            <div className="tooltip">ⓘ<span className="tooltiptext">The ability to identify the number of user visit opportunities that are missing for your domains.</span></div>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">
                                                            <span className="tick_icon no">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  year-data">
                                                            <span className="tick_icon no">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  year-data">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  enterprise-data">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                    </tr>
                                                    <tr className="row-15 odd" role="row">
                                                        <td className="column-1">
                                                            Backlinks vs. Competitors
                                                            <div className="tooltip">ⓘ<span className="tooltiptext">The ability to track and compare the back links data with your competitors.</span></div>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  year-data">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  year-data">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  enterprise-data">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                    </tr>
                                                    <tr className="row-16 even" role="row">
                                                        <td className="column-1">
                                                            Instant Web-pages Audit
                                                            <div className="tooltip">ⓘ<span className="tooltiptext">The ability to check technical SEO audit &amp; performance of your web pages instantly.</span></div>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data month-data active">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  year-data">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  year-data">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                        <td className="column-2 all_plans_data  enterprise-data">
                                                            <span className="tick_icon yes">?</span>
                                                        </td>
                                                    </tr>
                                                    <tr className="row-17 odd" role="row">
                                                        <td className="column-1" />
                                                        <td className="column-2" />
                                                        <td className="column-3" />
                                                        <td className="column-4" />
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/* .et_pb_text */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Plan Include */}
                <div className="plan_incluse_bg_outer_wrap">
                    <div className="container">
                        <h2>All <span>Plans</span> Include</h2>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <ul>
                                    <li>Insightful Dashboard</li>
                                    <li>Preferred Page Rank Tracking</li>
                                    <li>Locale Based Rank Tracking</li>
                                    <li>Page Visibility Score</li>
                                    <li>Competitor Rank Tracking</li>
                                    <li>Page-Wise Rank Tracking</li>
                                    <li>Instant Technical Audit</li>
                                    <li>Webpage Usability &amp; Accessibility</li>
                                </ul>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <ul>
                                    <li>Website Traffic</li>
                                    <li>On-Page Data Extractor</li>
                                    <li>Competitor On-Page Data</li>
                                    <li>Site Audit</li>
                                    <li>Page Health</li>
                                    <li>Internal Links</li>
                                    <li>Page Source &amp; Outbound Links</li>
                                    <li>External Links</li>
                                </ul>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <ul>
                                    <li>Webpage User Experience</li>
                                    <li>Code Audit</li>
                                    <li>4Xx/ 5Xx Link Monitoring</li>
                                    <li>Bulk Link Status Checker</li>
                                    <li>Social Traffic Analytics</li>
                                    <li>Top Pages Trend</li>
                                    <li>Top Performing Web Pages</li>
                                    <li>Search Queries</li>
                                </ul>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 plan_include-hide">
                                <div className="plan_iclude_bg_img_wrap">
                                    <img src="https://www.elitesiteoptimizer.com/assets/eso/images/home_image/plans_include_bg.png" alt="plans_include_bg" />
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <ul>
                                    <li>Branded vs. Non-branded keywords</li>
                                    <li>Backlink vs. competitor’s</li>
                                    <li>Backlink vs. Avg. ranking</li>
                                    <li>Group &amp; sections based reports on a website</li>
                                    <li>Staging site on-page validator (regression)</li>
                                    <li>XML sitemap generator</li>
                                    <li>Search engine traffic analytics</li>
                                </ul>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <ul>
                                    <li>Image, Video &amp; Html Sitemap Generator</li>
                                    <li>White-Label Pdf Reports</li>
                                    <li>Recommended Action Items</li>
                                    <li>Custom Crawl Requests</li>
                                    <li>User Delegation To Domain</li>
                                    <li>Schema Markup/ Json-Ld Validator</li>
                                    <li>Weekly Summary Of Rank, On-Page &amp; Links Reports</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End */}
                {/* Help */}
                <div className="container pricing_faq_outer_wrap">
                    <div className="ecommerce_faq_accordion_outer_wrap">
                        <h3><span>Questions?</span> Look Here</h3>
                        <h5>Can’t find an answers? Call us at <a href="tel:+17323072655"> +1 (732) 307 2655</a> or email <a href="mailto:sales@elitesiteoptimizer.com">sales@elitesiteoptimizer.com</a></h5>
                        <div className="seo_accordion_inner_wrap">
                            <div className="accordion" id="ecommerce_faq_accordion">
                                <div className="card">
                                    <div className="card-header" id="seo_accordion_0">
                                        <h2 className="mb-0">
                                            <button type="button" className="btn btn-link" data-toggle="collapse" data-target="#ecommerce_faq_accordion_collapse_0"><i className="eso_icon eso_Plus_icon" />What are the payment types that you accept?</button>
                                        </h2>
                                    </div>
                                    <div id="ecommerce_faq_accordion_collapse_0" className="collapse" aria-labelledby="seo_accordion_0" data-parent="#ecommerce_faq_accordion">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12">
                                                    We accept the following payment methods. Please contact for Bank-wire transfer details.                                 <ul>
                                                        <li>Credit Card payment</li>
                                                        <li>PayPal</li>
                                                        <li>Bank wire transfer</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="seo_accordion_1">
                                        <h2 className="mb-0">
                                            <button type="button" className="btn btn-link" data-toggle="collapse" data-target="#ecommerce_faq_accordion_collapse_1"><i className="eso_icon eso_Plus_icon" />Do you provide a custom crawler development service for my business needs?</button>
                                        </h2>
                                    </div>
                                    <div id="ecommerce_faq_accordion_collapse_1" className="collapse" aria-labelledby="seo_accordion_1" data-parent="#ecommerce_faq_accordion">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12">
                                                    Yes, we also offer custom web crawler development services according to your business needs.                                 <ul>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="seo_accordion_2">
                                        <h2 className="mb-0">
                                            <button type="button" className="btn btn-link" data-toggle="collapse" data-target="#ecommerce_faq_accordion_collapse_2"><i className="eso_icon eso_Plus_icon" />Are there any hidden fees or commitments?</button>
                                        </h2>
                                    </div>
                                    <div id="ecommerce_faq_accordion_collapse_2" className="collapse" aria-labelledby="seo_accordion_2" data-parent="#ecommerce_faq_accordion">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12">
                                                    No, there are no hidden fees or minimum commitments for subscribing to Elite Site Optimizer plans. You can cancel or pause your plan when not it is not needed.                                 <ul>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="seo_accordion_3">
                                        <h2 className="mb-0">
                                            <button type="button" className="btn btn-link" data-toggle="collapse" data-target="#ecommerce_faq_accordion_collapse_3"><i className="eso_icon eso_Plus_icon" />Do you support Business Intelligence tools like Tableau?</button>
                                        </h2>
                                    </div>
                                    <div id="ecommerce_faq_accordion_collapse_3" className="collapse" aria-labelledby="seo_accordion_3" data-parent="#ecommerce_faq_accordion">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12">
                                                    Yes, Elite Site Optimizer offers data for the Business Intelligence Tools like Tableau, PowerBI, etc. So you can view your website’s SEO data in the multi-dimensional view.                                 <ul>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="seo_accordion_4">
                                        <h2 className="mb-0">
                                            <button type="button" className="btn btn-link" data-toggle="collapse" data-target="#ecommerce_faq_accordion_collapse_4"><i className="eso_icon eso_Plus_icon" />I have a large website and need only on page analysis &amp; link checker? Do you offer a custom plan?</button>
                                        </h2>
                                    </div>
                                    <div id="ecommerce_faq_accordion_collapse_4" className="collapse" aria-labelledby="seo_accordion_4" data-parent="#ecommerce_faq_accordion">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12">
                                                    Yes, We offer a custom plan through which you can subscribe to the selected features of Elite Site Optimizer.                                 <ul>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="seo_accordion_5">
                                        <h2 className="mb-0">
                                            <button type="button" className="btn btn-link" data-toggle="collapse" data-target="#ecommerce_faq_accordion_collapse_5"><i className="eso_icon eso_Plus_icon" />Can I cancel or downgrade my plan in the future?</button>
                                        </h2>
                                    </div>
                                    <div id="ecommerce_faq_accordion_collapse_5" className="collapse" aria-labelledby="seo_accordion_5" data-parent="#ecommerce_faq_accordion">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12">
                                                    Yes, You can cancel or pause or downgrade your account according to your business needs in the future. Please contact us for any change request in your subscription plan.                                 <ul>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End */}
            {/* CTA */}
            <div className="cta_bg_wrap">
                <div className="cta_bg_img_left_outer_wrap">
                    <img src="https://www.elitesiteoptimizer.com/assets/eso/images/home_image/bg_4.png" alt="CTA_bg" />  
                </div>
                <div className="cta_bg_img_right_outer_wrap">
                    <img src="https://www.elitesiteoptimizer.com/assets/eso/images/home_image/bg_3.png" alt="CTA_bg" />  
                </div>
                <div className="container">
                    <div className="cta_content_outer_wrap">
                        <h4><span>Let’s Get Started,</span><br />Contact The Experts <br />And Boost Your SEO Today!</h4>
                        <HashLink to="/contact-us/?subject=custom-plan-request" elementId="contact_form_test">Request Free Trial</HashLink>
                    </div>
                </div>
            </div>
            {/* End CTA */}
            {/* Footer */}
            {/* #et-main-area */}
            {/* <div className="main-addon hide-overlay">
                <div className="overlay">
                    <div className="overlay-content">
                        <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
                        <iframe title="support" src={`${process.env.REACT_APP_DOMAIN}/addon/kb/`}  />
                    </div>
                </div>
                <div className="buttons-container">
                    <button className="button supportBTN"><i className="fa fa-search" />Support</button>
                </div>
            </div>  */}
            {
            /*Start of Zendesk Chat Script*/}
            {/*End of Zendesk Chat Script*/}{/*'}'*/}
            {/* <div class="cta_bg_wrap">
    <div class="cta_bg_img_left_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_4.png" alt="CTA_bg"> </div>
    <div class="cta_bg_img_right_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_3.png" alt="CTA_bg"> </div>
    <div class="container">
        <div class="cta_content_outer_wrap">
            <h4><span>Schedule A Meeting</span> To Take<br>Your Business To The Next Level.</h4> <a href="#">Get Your Free Trial</a> </div>
    </div>
</div> */}
            {/* #page-container */}
        </div>
    )
}

export default Signup;