import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Sidebar from "./Sidebared.js";
import "./Dashboard.css";
import { MDBDataTable } from "mdbreact";
import "mdbreact/dist/css/mdb.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "mdbreact/dist/css/mdb.css";
import { useNavigate } from "react-router-dom";

const ContactForm = () => {
  const [posts, setPosts] = useState([]);
  const [usersForRender, setUsersForRender] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromdate, setfromdate] = useState("");
  const [todate, settodate] = useState("");
  const [errmsg, setErrmsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    callUserdataApi();
  }, []);
  const exportapiurl = `${process.env.REACT_APP_API_URL}/contactexport/?from_date=${fromdate}&to_date=${todate}`;
  const onFromchange = (event) => {
    setfromdate(event.target.value);
  };
  const onTochange = (event) => {
    if (fromdate < event.target.value) settodate(event.target.value);
  };
  const handleSubmit = async (event) => {
    setErrmsg("")
    setLoading(true);
    event.preventDefault();
    if (fromdate == "") {
      setErrmsg("From Date is required");
      return false;
    }
    if (todate == "") {
      setErrmsg("To Date is required");
      return false;
    }
    if (fromdate > todate) {
      setErrmsg("Please Enter Valid Error Message");
      return false;
    }
    setErrmsg("")
    var filterurl = `${process.env.REACT_APP_DOMAIN}/call-response-Contact`;
    const datavalue = await axios.post(filterurl, {
      fromdate: fromdate,
      todate: todate,
    });
    const dataval = datavalue.data.data;
    let userData = [];
    dataval.map((item, index) => {
      item.action = (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className="uil-trash-alt"
            style={{
              cursor: "pointer",
              color: "black",
              fontSize: ".7em",
              padding: ".5rem",
              borderRadius: ".3rem",
              background: "#fb6262",
            }}
            onClick={() => viewPost(dataval[index].id)}
          >
            View
          </div>
        </div>
      );
      userData.push(item);
    });

    setUsersForRender(datavalue.data.data);
    setCardData(datavalue.data.data);
    setLoading(false);
  };

  const resetClick = () => {
    window.location.reload(false);
  };
  const handleClick = () => {
    setErrmsg("")
    if (fromdate == "") {
      setErrmsg("From Date is required");
      return false;
    }
    if (todate == "") {
      setErrmsg("To Date is required");
      return false;
    }

    if (fromdate > todate) {
      setErrmsg("Please Enter Valid Error Message");
      return false;
    }
    setErrmsg("")
    window.open(exportapiurl, "_blank");
    callUserdataApi();
  };

  const callUserdataApi = async () => {
    setLoading(true);
    setfromdate("");
    settodate("");
    const datavalue = await axios.post(
      `${process.env.REACT_APP_DOMAIN}/call-response-Contact`,
      { fromdate: fromdate, todate: todate }
    );
    const dataval = datavalue.data.data;
    let userData = [];
    dataval.map((item, index) => {
      item.action = (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className="uil-trash-alt"
            style={{
              cursor: "pointer",
              color: "black",
              fontSize: ".7em",
              padding: ".5rem",
              borderRadius: ".3rem",
              background: "#fb6262",
            }}
            onClick={() => viewPost(dataval[index].id)}
          >
            View
          </div>
        </div>
      );
      userData.push(item);
    });

    setUsersForRender(userData);
    setCardData(userData);
    setLoading(false);
  };
  let viewPost = (postId) => {
    return navigate(`/contactview/${postId}`);
  };

  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: cardData,
  };
  return (
    <section className="menu-table tog_menu_table_exit">
      <div className="user">
        <div className="user5">
          <h3>Contacts list</h3>
          <div className="link-set">
            <div className="link">
              <a href="">
                Home <span> &gt;</span>{" "}
              </a>
              <a href="" className="active">
                {" "}
                Contacts list
              </a>
            </div>
          </div>
        </div>
        <div>
          <form className="us_fm_outer_wrap" onSubmit={handleSubmit}>
            <div className="us_fm_outer_wrap">
              <label>From Date:</label>
              <input
                type="date"
                className="us_li_fm_date"
                value={fromdate}
                onChange={onFromchange}
              />
              <label className="us_fm_to_lbl">To Date:</label>
              <input
                type="date"
                className="us_li_to_date"
                value={todate}
                onChange={onTochange}
              />
            </div>
            <div className="ft_et_wrap">
              <button type="submit">Filter</button>
              <button type="button" onClick={handleClick}>Export</button>
              <button type="button" onClick={resetClick}>Reset</button>
            </div>
          </form>
          <p className="err_message Date_err">{errmsg}</p>
        </div>

        <div className="tset">
          <MDBDataTable
            theadColor="#454f71"
            small
            responsive
            bordered
            hover
            noBottomColumns
            exportToCSV
            data={data}
          />
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
