import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const CookiesPopup = () => {

  const [agree, setAgree] = useState(true)
  const [dummyvar, setdummyvar] = useState(localStorage.getItem('cookieAgree'))

  useEffect(() => {
  
  if(dummyvar==1 ){
    setAgree(false)
   }
},[dummyvar])
  const onAgree = () => {
    setAgree(false)
     setdummyvar(1)
    localStorage.setItem('cookieAgree', 1)
      // $('.cookie_notice_container').html('<span class="cn_notice_text" style="color:#28a745;">By continuing with this website you are agreeing upon our cookie policy.</span>');
      // $('#cookie-notice').delay(5000).hide("slow");
 
    
   
  }

 


  return (
    <div id="cookie-notice" className="cn_bottom" style={{ color: 'rgb(255, 255, 255)', visibility: 'visible', backgroundColor: 'rgb(0, 0, 0)' }}>
{agree?

      <div className="cookie_notice_container"> <span className="cn_notice_text">We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.</span><a onClick={()=>{onAgree()}} className="cn_set_cookie btn-primary btn-sm left_margin_15">I Agree</a><Link to="/privacy-policy" target="_blank" className="left_margin_15 btn-primary btn-sm cn_more_info">Privacy policy</Link> </div>:""
      // <span className= "cn_notice_text" style={{color: '#28a745'}}>By continuing with this website you are agreeing upon our cookie policy.</span>
      }
    </div>
  )
}

export default CookiesPopup;