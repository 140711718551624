import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Categories from './Categories';
import RecentPosts from './RecentPosts';
import './kb.css';

const KnowledgeBase = () => {

    const kblistapi = `${process.env.REACT_APP_BLOGS_API}/kb-lists`
    // https://strapi.egrovetech.com/kb-lists?url_slug=link-analysis

    const [listData, setListData] = useState([])
    const [sValue, setSValue] = useState()

    // const callKBapi = async () => {
    //     await axios.get(kblistapi, {
    //         headers: {
    //             Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTYsImlhdCI6MTY3ODAyODIyNywiZXhwIjoxNjgwNjIwMjI3fQ.bPUmh-sonV0pGKe6YokBiqLQG-49FCgVEcZczAbyRWo'
                
    //         }
    //     }).then((res) => {
    //         console.log(res.data)
    //         setListData(res.data)
    //     })
    // }

    const callKBapi = async () => {
        
          const dataParams = {
            token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
          }
          await axios.post(`${process.env.REACT_APP_DOMAIN}/call-kb-lists`, dataParams).then((res) => { setListData(res.data) }).catch((error) => { console.log(error) })
       
      }

    const navigate = useNavigate();
    const handleChange = (e) => {
        setSValue(e.target.value);
        localStorage.setItem('sValue', e.target.value)
    }
    const onFormsubmit = e =>{
        if (e.keyCode === 13 || e.key === 'Enter' ) {
        navigate('/kb/search')
        }
    }
    const submit = e =>{
        
        navigate('/kb/search')
    }
    

    useEffect(() => {
        window.scroll(0, 0)
        callKBapi()
    }, [])

    return (
        <div>           
            {/* custom_css and Js  */}
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/bootstrap.min.css" />
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/font-awesome.min.css" />
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/hfstyle.css" />
            {/* #main-header */}
            <div id="et-main-area">
                {/*Google Tag Manager (noscript) */}
                {/* Google Tag Manager (noscript) */}
                <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
                    height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
                {/* End Google Tag Manager (noscript) */}
                <div id="main-content" className="kb_knowledge_page kb_page search_issue">
                    <div className="kb_banner" style={{}}>
                        <style dangerouslySetInnerHTML={{ __html: "\n\t\n\n.kb_banner   { background-image: url(https://www.elitesiteoptimizer.com/public/assets/eso/images/services/Call_kb_jpg_1_1da6d65784.jpeg); }\n\t@media  only screen and (max-width:768px){\n.kb_banner   { background-image: url(https://www.elitesiteoptimizer.com/public/assets/eso/images/services/medium_Call_kb_jpg_1_1da6d65784.jpeg);}\n}\n\n\t@media  only screen and (max-width:480px){\n.kb_banner   { background-image: url(https://www.elitesiteoptimizer.com/public/assets/eso/images/services/small_Call_kb_jpg_1_1da6d65784.jpeg);background-position: center;\n }\n\n\t}\t\n" }} />
                        <div className="kb_cont">
                            <h2>How Can We Help?</h2>
                            <div className="pakb-header">
                                <form role="search" id="kbsearchform" >
                                    <div className="pakb-search">
                                        <input type="hidden" name="_token" defaultValue="FYxBqJ0e0SYwiiZBsth2PSJvuyKjoqb81YljCbAD" />                       
                                         <input value={sValue} onChange={handleChange} type="text" name="s" placeholder="Search" id="tags" className="autosuggest"
                                           onKeyPress={onFormsubmit} />
                                         <span class="fa fa-search searchbutton"><input onClick={submit} id="searchsubmit" /></span>
                                        <input type="hidden" name="post_type" defaultValue="knowledgebase" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>      
                    <div className="container top_margin_50">
                        <div id="content-area" className="clearfix">
                            <div className="common_inner_text col-12">
                                <h1 className="main_title common_center">Knowledgebase</h1>
                            </div>
                            <div className="row top_margin_50">
                                <div id="left-area" className="col-md-8 bottom_padding_50">
                                    <div className="common_text_inner row col-xs-12">
                                        {listData.map((items, index) => {
                                            return (
                                                <div className="geeting_cont col-md-12 col-sm-12 col-lg-6 col-xs-12">
                                                    <h2><i className={items.icon} /><HashLink to={`${items.url_slug}`}>{items.name}</HashLink></h2>
                                                    <ul className="listcntr">
                                                        {items.kb_cms.slice(0, 4).map((subItems) => {
                                                            return (
                                                                <li><Link to={`/knowledge-based/${subItems.url_slug}`} elementId='root'>{subItems.title}</Link></li>
                                                            )
                                                        })}
                                                        {items.kb_cms.length > 4 ? <li className="showwmore top_margin_15" style={{ float: 'right' }}><Link className="showmorebtn" to={`${items.url_slug}`} state={{ from: `${items.url_slug}` }}> Show more...</Link></li> : ''}
                                                    </ul>
                                                </div>
                                            )
                                        })}
                                        
                                    </div>
                                </div>
                                <div id="sidebar" className="col-md-4">
                                    {/* <div id="search-3" className="kb_widget kb_search">
                                        <form role="search" method="post" id="searchform" className="searchform" action="https://www.elitesiteoptimizer.com/kb/search/">
                                            <div>
                                                <input type="hidden" name="_token" defaultValue="FYxBqJ0e0SYwiiZBsth2PSJvuyKjoqb81YljCbAD" />                            <label className="screen-reader-text" htmlFor="s">Search for:</label>
                                                <input type="text" defaultValue name="s" id="s" />
                                                <input type="submit" id="searchsubmit" defaultValue="Search" />
                                            </div>
                                        </form>
                                    </div> */}
                                    <Categories />
                                    {/* end .kb_widget */}
                                    <RecentPosts />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* #et-main-area */}
            {/* <div className="main-addon hide-overlay">
                <div className="overlay">
                    <div className="overlay-content">
                        <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
                        <iframe title="support" src="https://www.elitesiteoptimizer.com/addon/kb/" />
                    </div>
                </div>
                <div className="buttons-container">
                    <button className="button supportBTN"><i className="fa fa-search" />Support</button>
                </div>
            </div> */}
            {/*Start of Zendesk Chat Script*/}
            {/*End of Zendesk Chat Script*/}


            {/* #main-footer */}
            {/* #page-container */}
        </div>
    )
}

export default KnowledgeBase;